import React from "react";
import LocomotiveScroll from "locomotive-scroll";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import video from "./video.mp4";
import "../Qualify_Component/styles/styles.css";
import PartnerCard from "./PartnerCard";

const Partner = () => {
  return (
    <div id="partners">
      <SkeletonTheme baseColor="#482b94" highlightColor="#583e9e">
        <div id="page">
          <a id="top"></a>

          <main className="page-template-page-mintings back_under">
            <section>
              <div className="bg">
                <div className="gradient-top"></div>
                <div className="gradient-bottom"></div>
              </div>
              <div className="row">
                <h1 className="hero_head" style={{ textAlign: "center" }}>
                  
                  <br />
                  <br />
                  Partners
                  <br />
                  
                </h1>
              </div>
              <h1 className=" h1 head_underz ">CRYPTO CREW</h1>
              <br />
              <div>
                <PartnerCard />
              </div>
              <br />
              <br />
              <div className="row">
                <div className="flex grid">
                  <div className="gradient-bottom"></div>
                </div>
              </div>
            </section>
          </main>
        </div>
      </SkeletonTheme>
    </div>
  );
};

export default Partner;
