import React from "react";
import "./styles/fonts.css";
import "./styles/normalize.css";
import "./styles/animate.min.css";
import "./styles/style.css";
import "react-loading-skeleton/dist/skeleton.css";
import Mint from "./mint.jsx";

const Minting = () => {
  return (
    <div>
      <Mint />
    </div>
  );
};

export default Minting;
