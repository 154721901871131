import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "../Hero.scss";
import "../Qualify_Component/styles/style.css";
import hero from "./anim.jpg";
import Navbar from "../NavBar/Navbar";
import Chapter1 from "./Video/Chap1.mp4";

const HeroSection = () => {
  return (
    <div>
      <SkeletonTheme
        baseColor="#482b94"
        highlightColor="#583e9e"
        className="bodys"
      >
        <div id="page">
          <a id="top"></a>
          <main className="page-template-page-minting">
            <section id="hero">
              <div className="bg">
                <div className="gradient-top"></div>
                <div className="gradient-bottom"></div>
              </div>
              <div className="row">
                <h1>Crypto Crew Story </h1>
              </div>
              <br />
              <br />
              <br />
              <br />
              <br />
              <p className="hsb">Chapter 1</p>
              <br />
              <br />
              <div className="video_css">
                <video
                  style={{ width: "80%", borderRadius: "20px" }}
                  controls
                  className="video_css zindex"
                >
                  <source src={Chapter1} type="video/mp4" />
                  <source src={Chapter1} type="video/ogg" />
                </video>
              </div>
            </section>
          </main>
        </div>
      </SkeletonTheme>
    </div>
  );
};

export default HeroSection;
