import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import TDSldier from "./TeamSlider";
import { Grid } from "@mui/material";
import Cards from "./SetCardsThree";
import video from "./song.mp4";
const Set3 = () => {
  return (
    <div id="set3">
      <SkeletonTheme baseColor="#482b94" highlightColor="#583e9e">
        <div id="page">
          <a id="top"></a>
          <main className="page-template-page-minting">
            <section id="hero">
              <div className="bg">
                <div className="gradient-top"></div>
                <div className="gradient-bottom"></div>
              </div>
            
              <div className="row">
                <h1 className="hero_head">
                  <br />
                  SET 3
                  
                </h1>

                <Grid container>
                  <Grid md={12} lg={12} xs={12} sm={12}>
                  <br />
                  <div className="video_css">
                    <video
                      style={{ width: "35%", borderRadius: "20px" }}
                      controls
                      className="video_css"
                    >
                      <source src={video} type="video/mp4" />
                      <source src={video} type="video/ogg" />
                    </video>
                  </div>
                  <br />
                    {" "}
                    <br />
                    <p className=" head_underz">
                      CRYPTO CREW TRADING CARDS
                      <br />
                    </p>
                    <p className=" h1 head_under_second">
                      Animated, Two-sided Set
                    </p>
                    <br />
                    <Cards />
                  </Grid>
                 
                </Grid>
               

                <div className="flex grid">
                  <div className="image"></div>

                  <div className="gradient-bottom"></div>
                </div>
              </div>
            </section>
          </main>
        </div>
      </SkeletonTheme>
    </div>
  );
};

export default Set3;
