import React, { useRef, useState } from "react";
// Import Swiper React components
import "./style.css";
import { Grid } from "@mui/material";

export default function App() {
  return (
    <>
      <Grid container spacing={3}>
        <Grid item md={6} lg={6} xs={12} sm={12}>
          <div className="roadmp">
            {" "}
            <h2
              className="font_nav_texts  head_underz font_under_roads"
              style={{ color: "#fff" }}
            >
              <br />
              PHASE 1
              <br />
            </h2>
            <h2
              className="font_nav_texts  font_under_road"
              style={{ color: "#fff", textAlign: "center" }}
            >
              <br />
              AUGUST/SEPTEMBER 2022
              <br />
              <br />
              <li> Pre-launch Marketing</li>
              <br />
              <li> Finish Artwork (Heroes/Villains)</li>
              <br />
              <li> Launch Website</li>
              <br />
              <li> Launch Heroes Program</li>
              <br />
              <li> Establish Social Media Presence</li>
              <br />
              <li> Smart Contracts/Development</li>
              <br />
              <li> Partnerships with Marketplaces</li>
              <br />
            </h2>
          </div>
        </Grid>
        <Grid item md={6} lg={6} xs={12} sm={12}>
          <div className="roadmp">
            {" "}
            <h2
              className="font_nav_texts  head_underz font_under_roads"
              style={{ color: "#fff" }}
            >
              <br />
              PHASE 2
              <br />
            </h2>
            <h2
              className="font_nav_texts  font_under_road"
              style={{ color: "#fff", textAlign: "center" }}
            >
              <br />
              QUARTER 4 2022
              <br />
              <br />
              <li> Set 1 Minting Begins</li>
              <br />
              <li> Merchandise Design</li>
              <br />
              <br />
            
            </h2>
          </div>
        </Grid>
        <Grid item md={6} lg={6} xs={12} sm={12}>
          <div className="roadmp">
            {" "}
            <h2
              className="font_nav_texts  head_underz font_under_roads"
              style={{ color: "#fff" }}
            >
              <br />
              PHASE 3
              <br />
            </h2>
            <h2
              className="font_nav_texts  font_under_road"
              style={{ color: "#fff", textAlign: "center" }}
            >
              <br />
              QUARTER 1 2023
              <br />
              <br />
              <li> Finish Song for Each Superhero</li>
              <br />
              <li> Launch Online Store</li>
              <br />
              <li> Launch $CREW token</li>
              <br />
            </h2>
          </div>
        </Grid>
        <Grid item md={6} lg={6} xs={12} sm={12}>
          <div className="roadmp">
            {" "}
            <h2
              className="font_nav_texts  head_underz font_under_roads"
              style={{ color: "#fff" }}
            >
              <br />
              PHASE 4
              <br />
            </h2>
            <h2
              className="font_nav_texts  font_under_road"
              style={{ color: "#fff", textAlign: "center" }}
            >
              <br />
              QUARTER 2 2023
              <br />
              <br />
              <li> Launch DAO</li>
              <br />
              <li> Set 2 Minting Begins</li>
              <br />
              <li> Exclusive Access to Metaverse</li>
              <br />
            </h2>
          </div>
        </Grid>
        <Grid item md={6} lg={6} xs={12} sm={12}>
          <div className="roadmp">
            {" "}
            <h2
              className="font_nav_texts  head_underz font_under_roads"
              style={{ color: "#fff" }}
            >
              <br />
              PHASE 5
              <br />
            </h2>
            <h2
              className="font_nav_texts  font_under_road"
              style={{ color: "#fff", textAlign: "center" }}
            >
              <br />
              Quarter 3 2023
              <br />
              <br />
              <li> Finish Chapters 1-6</li>
              <br />
              <li> Release of Set 3</li>
              <br />
            </h2>
          </div>
        </Grid>
        <Grid item md={6} lg={6} xs={12} sm={12}>
          <div className="roadmp">
            {" "}
            <h2
              className="font_nav_texts  head_underz font_under_roads"
              style={{ color: "#fff" }}
            >
              <br />
              PHASE 6
              <br />
            </h2>
            <h2
              className="font_nav_texts  font_under_road"
              style={{ color: "#fff", textAlign: "center" }}
            >
              <br />
              Quarter 1 2024
              <br />
              <br />
              <li> Crypto Crew Animated Series </li>
              <br />
              <li>Crypto Crew Play and Earn Gam</li>
              <br />
            </h2>
          </div>
        </Grid>
      </Grid>
    </>
  );
}
