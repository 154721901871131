import React from "react";
import LocomotiveScroll from "locomotive-scroll";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import video from "./video.mp4";
import "../Qualify_Component/styles/styles.css";
import Divider from "@mui/material/Divider";
import StoreCards from "./StoreCards";
import "./Cardu.scss";
import Chart from "./Charts";
import { Grid } from "@mui/material";
const Store = () => {
  return (
    <div>
      <SkeletonTheme baseColor="#482b94" highlightColor="#583e9e">
        <div id="page">
          <a id="top"></a>

          <main className="page-template-page-mintings back_under">
            <section>
              <div className="bg">
                <div className="gradient-top"></div>
                <div className="gradient-bottom"></div>
              </div>

              <h1 className=" h1 head_under">MORE OF WHAT'S IN STORE</h1>

              <br />
              <br />

              <div className="container">
                <StoreCards />
              </div>

              <div className="ct_pa store_bg gradient">
                {" "}
                <p className="hsb">$CREW TOKENOMICS.</p>
                <p className=" h1 head_under_second">
                  -200M $CREW, Tokens Initial Harvest
                  <br />
                  <br />
                  -200M $CREW, Future Harvest (100 Million for Collection 2, 100
                  Million for Collection 3)
                  <br />
                  <br />
                  -200M $CREW, P2E Game and Stake Rewards
                  <br />
                  <br />
                  -50M $CREW, Private Sale
                  <br />
                  <br />
                  -180M $CREW, Core Team
                  <br />
                  <br />
                  -150M $CREW, Reserved for Future Usage
                  <br />
                  <br />
                  -20M $CREW, 0xPioneer Developer Allocation
                  <br />
                  <br />
                  <Divider variant="middle" />
                  <br />
                  <br />
                  1,000,000,000.00 Total Supply
                </p>
                <br />
                <Grid container>
                  <Grid
                    item
                    md={12}
                    lg={12}
                    xs={12}
                    sm={12}
                    style={{
                      textAlign: "center",
                      alignItems: "center",
                      justifyContent: "center",
                      display: "grid",
                    }}
                  ></Grid>
                </Grid>
              </div>

              <div className="row">
                <div className="flex grid">
                  <div className="gradient-bottom"></div>
                </div>
              </div>
            </section>
          </main>
        </div>
      </SkeletonTheme>
    </div>
  );
};

export default Store;
