import React from "react";
import Header from "../NavBar/Navbar";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import Headers from "../NavBar/MobNav";
import UnderHeader from "../component/UnderHead";
import Roadmap from "../component/Roadmap";
import OurTeam from "../component/OurTeam";
import Set1 from "../component/Set";
import Set2 from "../component/Set2";
import Set3 from "../component/Set3";
import Store from "../component/Store";
import Partner from "../component/Partner";
import LetMe from "../component/LetMe";

import Footer from "../component/Footer";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
function Garden() {
  const theme = useTheme();
  const smallScreen = useMediaQuery((theme) => theme.breakpoints.down("lg"));
  const iframeStyles = {
    width: '100%', // Make the iframe fill the entire container width
    height: '100%', // Make the iframe fill the entire container height
    border: 'none', // Remove the iframe border
  };
  return (
    <div className="garden">
      {/* THIS JAVASCRIPT CODE IS FOR Header of the Park Page*/}

      {/* <Slider /> */}
      {!smallScreen && (
        <div style={{ backgroundColor: " #160f22 " }}>
        
          <div className="">
<iframe
  src="https://mnfst-6cdd7.web.app"
  title="Embedded Website"
  allowFullScreen
  frameBorder="0"
  sandbox="allow-scripts allow-same-origin"
  style={{
    width: '100%',
    height: '100%',
  }}
/>


  </div>
       
        </div>
      )}
      {/* THIS JAVASCRIPT CODE IS FOR the Slider used for the Desktop View when you press on CustomSlider with ctrl and right arrow you move towards the Slider Page Desktop View*/}
      {smallScreen && (
        <div style={{ backgroundColor: " #160f22 " }}>
     
         
          <div style={{ position: 'relative', paddingBottom: '56.25%', height: 0 }}>
      {/* The paddingBottom creates a responsive aspect ratio (16:9 in this case) */}
      <iframe
        src="https://mnfst-6cdd7.web.app"
        title="Embedded Website"
        style={iframeStyles}
        allowFullScreen
      ></iframe>
    </div>
         
        </div>
      )}
      {/* THIS JAVASCRIPT CODE IS FOR the Slider used for the Mobile View when you press on MobileSlider with ctrl and right arrow you move towards the Slider Page Mobile View*/}
    </div>
  );
}

export default Garden;
