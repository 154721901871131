import React from "react";
import { Grid } from "@mui/material";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import {
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBCardImage,
  MDBBtn,
  MDBRipple,
} from "mdb-react-ui-kit";
import dao from "./image/dao.png";
import crew from "./image/crew.jpg";
import game from "./image/game.jpg";
import metaverse from "./image/metaverse.jpg";
import mer from "./image/MERCHANDISE.jpg";
import music from "./image/MERCHANDISE.jpg";

const StoreCards = () => {
  return (
    <div className="container">
      <br />
      <br />
      <Grid container spacing={6}>
        <Grid item md={6} lg={4} xs={12} sm={12}>
          <Card className="store_bg gradient" style={{ textAlign: "Center" }}>
            <br />
            <br />
            <CardMedia
              sx={{ height: 130, width: 130 }}
              image={dao}
              title="PolygonPunks"
              className="picpa img_partner vert-move"
              style={{ borderRadius: "50%" }}
            />

            <CardContent>
              <Typography
                gutterBottom
                variant="h5"
                component="div"
                className="   head_underz "
                style={{ fontSize: "25px", color: "#fff" }}
              >
                DAO
              </Typography>
              <Typography variant="body2" color="text.secondary">
                <br />
                <a
                  className="font_nav_texts "
                  style={{ fontSize: "13px", color: "#fff" }}
                >
                  All who own a CRYPTO CREW NFT will become members of the
                  CRYPTO CREW DAO and have voting rights. Those who mint all 10
                  SUPERHEROES are admitted to the CIRCLE OF HEROES.
                </a>
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item md={6} lg={4} xs={12} sm={12}>
          <Card className="store_bg gradient" style={{ textAlign: "Center" }}>
            <br />
            <br />
            <CardMedia
              sx={{ height: 130, width: 130 }}
              image={crew}
              title="PolygonPunks"
              className="picpa img_partner vert-move"
              style={{ borderRadius: "50%" }}
            />

            <CardContent>
              <Typography
                gutterBottom
                variant="h5"
                component="div"
                className="   head_underz "
                style={{ fontSize: "25px", color: "#fff" }}
              >
                $CREW
              </Typography>
              <Typography variant="body2" color="text.secondary">
                <br />
                <a
                  className="font_nav_texts "
                  style={{ fontSize: "13px", color: "#fff" }}
                >
                  The CRYPTO CREW will release a $CREW token, and NFT holders
                  will be able to harvest this token.
                </a>
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item md={6} lg={4} xs={12} sm={12}>
          <Card className="store_bg gradient" style={{ textAlign: "Center" }}>
            <br />
            <br />
            <CardMedia
              sx={{ height: 130, width: 130 }}
              image={game}
              title="PolygonPunks"
              className="picpa img_partner vert-move"
              style={{ borderRadius: "50%" }}
            />

            <CardContent>
              <Typography
                gutterBottom
                variant="h5"
                component="div"
                className="   head_underz "
                style={{ fontSize: "25px", color: "#fff" }}
              >
                P2E GAME
              </Typography>
              <Typography variant="body2" color="text.secondary">
                <br />
                <a
                  className="font_nav_texts "
                  style={{ fontSize: "13px", color: "#fff" }}
                >
                  When your superhero collects $1 trillion, he advances to the
                  next city. If he is unable to fend off the attacks of the
                  villains and loses all his crypto, the game ends.
                </a>
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item md={6} lg={4} xs={12} sm={12}>
          <Card className="store_bg gradient" style={{ textAlign: "Center" }}>
            <br />
            <br />
            <CardMedia
              sx={{ height: 130, width: 130 }}
              image={metaverse}
              title="PolygonPunks"
              className="picpa img_partner vert-move"
              style={{ borderRadius: "50%" }}
            />

            <CardContent>
              <Typography
                gutterBottom
                variant="h5"
                component="div"
                className="   head_underz "
                style={{ fontSize: "25px", color: "#fff" }}
              >
                METAVERSE
              </Typography>
              <Typography variant="body2" color="text.secondary">
                <br />
                <a
                  className="font_nav_texts "
                  style={{ fontSize: "13px", color: "#fff" }}
                >
                  CRYPTO CREW NFT ownership will provide entrance into multiple
                  Metaverse experiences.
                </a>
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item md={6} lg={4} xs={12} sm={12}>
          <Card className="store_bg gradient" style={{ textAlign: "Center" }}>
            <br />
            <br />
            <CardMedia
              sx={{ height: 130, width: 130 }}
              image={mer}
              title="PolygonPunks"
              className="picpa img_partner vert-move"
              style={{ borderRadius: "50%" }}
            />

            <CardContent>
              <Typography
                gutterBottom
                variant="h5"
                component="div"
                className="   head_underz "
                style={{ fontSize: "25px", color: "#fff" }}
              >
                MERCHANDISE
              </Typography>
              <Typography variant="body2" color="text.secondary">
                <br />
                <a
                  className="font_nav_texts "
                  style={{ fontSize: "13px", color: "#fff" }}
                >
                  CRYPTO CREW merchandise will be designed, marketed, and made
                  available for sale.
                </a>
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item md={6} lg={4} xs={12} sm={12}>
          <Card className="store_bg gradient" style={{ textAlign: "Center" }}>
            <br />
            <br />
            <CardMedia
              sx={{ height: 130, width: 130 }}
              image={music}
              title="PolygonPunks"
              className="picpa img_partner vert-move"
              style={{ borderRadius: "50%" }}
            />

            <CardContent>
              <Typography
                gutterBottom
                variant="h5"
                component="div"
                className="   head_underz "
                style={{ fontSize: "25px", color: "#fff" }}
              >
                MUSIC
              </Typography>
              <Typography variant="body2" color="text.secondary">
                <br />
                <a
                  className="font_nav_texts "
                  style={{ fontSize: "13px", color: "#fff" }}
                >
                  Each of the 10 Crypto Superheroes has a unique song that has
                  been created. These songs will be released as NFTs.
                  <br />
                  <a  style={{color:"#837633"}} href="https://www.youtube.com/channel/UCscsCP_kMSaHTcqjbyzteNA">
                    More
                  </a>
                </a>
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <br />
      <br />
      <br />
    </div>
  );
};

export default StoreCards;
