import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import "./card.scss";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { useCollection } from "../hooks/useCrew";

function Wallets() {

  const { collection, loading, harvest, harvesting } = useCollection();
  
  return (
    <>
      
      <Grid container spacing={2}>
        {!!loading && collection.map((_, i) => (
          <Grid item md={4} lg={4} xs={12} sm={12} key={i}>
            <div className="nft">
              <div className="main">
                <Skeleton />
              </div>
            </div>
          </Grid>
        ))}
        {!loading && collection.map((_, i) => (
          <Grid item md={4} lg={4} xs={12} sm={12} key={i}>
            <div className="nft">
              <div className="main">
                <img className="tokenImage" src={_.image} alt="NFT" />
                <br />
                <h2
                  style={{
                    textAlign: "center",
                    color: "#fff",
                    fontWeight: "700",
                  }}
                >
                  {_.title}
                </h2>
                <button class="button" disabled={harvesting} onClick={() => harvest(_.token_id)}>{` Harvest ${_.harvestAmount} CREW`}</button>
              </div>
            </div>
          </Grid>
        ))}
      </Grid>
    </>
  );
}

export default Wallets;
