import React from "react";
import Header from "../NavBar/Navbar";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import GameHeroSection from "../Gaming/GameHeroSection";
import MobileGamingHero from "../Gaming/MobileGamingHero";
import Footer from "../component/Footer";
import Headers from "../NavBar/MobNav";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
function Garden() {
  const theme = useTheme();
  const smallScreen = useMediaQuery((theme) => theme.breakpoints.down("lg"));
  return (
    <div className="garden">
      {/* THIS JAVASCRIPT CODE IS FOR Header of the Park Page*/}

      {/* <Slider /> */}
      {!smallScreen && (
        <div style={{ backgroundColor: " #160f22 " }}>
          <Header />
          <GameHeroSection />
          <Footer />
        </div>
      )}
      {/* THIS JAVASCRIPT CODE IS FOR the Slider used for the Desktop View when you press on CustomSlider with ctrl and right arrow you move towards the Slider Page Desktop View*/}
      {smallScreen && (
        <div style={{ backgroundColor: " #160f22 " }}>
          <Headers />
          <MobileGamingHero />
          <Footer />
        </div>
      )}
      {/* THIS JAVASCRIPT CODE IS FOR the Slider used for the Mobile View when you press on MobileSlider with ctrl and right arrow you move towards the Slider Page Mobile View*/}
    </div>
  );
}

export default Garden;
