import React from "react";
import "./Footer.css";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import pdf from "../NavBar/litepaper.pdf";
const Footer = () => {
  return (
    <div>
      {" "}
      <SkeletonTheme baseColor="#482b94" highlightColor="#583e9e">
        <footer id="footer">
          <div className="row list">
            <div className="flex">
              <div className="left">
                <div id="footlogo"></div>

                <div className="navi">
                  <ul>
                    <li>
                      <a href="./">Home</a>
                    </li>
                    <li>
                      <a href="./roadmap">Roadmap</a>
                    </li>
                    <li>
                      <a href="./#ourteam">Team</a>
                    </li>
                    <li>
                      <a href="./#set1">Set1</a>
                    </li>
                    <li>
                      <a href="./#set2">Set2</a>
                    </li>
                    <li>
                      <a href="./#set3">Set3</a>
                    </li>
                    <li>
                      <a href="./#partners">Partners</a>
                    </li>
                    <li>
                      <a href={pdf} target="_blank()">
                        Litepaper
                      </a>
                    </li>
                  </ul>
                  <p>© Crypto Crew. All Rights Reserved.</p>
                </div>
              </div>
              <div className="right">
                <div id="foot-social">
                  <ul>
                    <li className="email">
                      <a href="mailto:thecryptocrew2022@gmail.com">
                        Contact Us
                      </a>
                    </li>
                    <li className="telegram">
                      <a target="_blank" href="https://t.me/+U29SX3Tr4PNlMzUx">
                        Telegram
                      </a>
                    </li>
                    <li className="twitter">
                      <a target="_blank" href="https://t.co/cwHB8R69tF">
                        Twitter
                      </a>
                    </li>
                    <li className="discord">
                      <a target="_blank" href="http://discord.gg/crewmunity">
                        Discord
                      </a>
                    </li>
                    <li className="instagram">
                      <a
                        target="_blank"
                        href="https://instagram.com/crypto._.crew?igshid=YmMyMTA2M2Y="
                      >
                        Instagram
                      </a>
                    </li>
                  </ul>
                </div>
                <p>© Crypto Crew. All Rights Reserved.</p>
              </div>
            </div>
          </div>
        </footer>
      </SkeletonTheme>
    </div>
  );
};

export default Footer;
