import React from "react";
import { FaTwitter } from "react-icons/fa";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import video from "./video.mp4";
import elkf from "./image/elkf.png";
import "../Qualify_Component/styles/styles.css";
import Timer from "./Timer.jsx";
import { Grid } from "@mui/material";
const UnderHead = () => {
  return (
    <div>
      <SkeletonTheme baseColor="#482b94" highlightColor="#583e9e">
        <div id="page">
          <a id="top"></a>

          <main className="page-template-page-mintings back_under">
            <section>
              <div className="bg">
                <div className="gradient-top"></div>
                <div className="gradient-bottom"></div>
              </div>
              <h1 className=" h1 head_under">
                Contract Address For $CREW Token
                <br /> <br />{" "}
                <span
                  className="font_nav_texts  head_underz"
                  style={{ fontSize: "20px", color: "#fff" }}
                >
                  {" "}
                  0x1f89600A0a08bC51Eef1BeC8E1CA826145753052
                </span>
                <br /> <br />{" "}
              </h1>

              <div className="container">
                <Grid item lg={12} sm={12} xs={12} md={12}>
                  {" "}
                  <Card className="card_bg box_Par">
                   

                    <CardContent>
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        style={{ textAlign: "center" }}
                      >
                        <br />

                        <a href="https://quickswap.exchange/#/swap?currency0=0x1f89600A0a08bC51Eef1BeC8E1CA826145753052&currency1=ETH&swapIndex=0">
                          <button class="button">Buy $Crew Token</button>
                        </a>
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>{" "}
                <h1 className=" h1 head_under">
                  <div id="dexscreener-embed">
                    <iframe src="https://dexscreener.com/polygon/0x143A7cBb73f918e9b029088e1328716C8491E920?embed=1&theme=dark%22%3E"></iframe>
                  </div>
                </h1>
              </div>

              <h1 className=" h1 head_under">
                <br /> <br />A group of sinister villains has launched an
                all-out attack, <br /> threatening the very existence of the
                industry.
              </h1>

              <br />
              <br />
              <p className=" h1 head_under_second">
                Can anyone save the crypto world from utter destruction? Can
                these <br /> villains be stopped before it’s too late? There is
                only one hope!
              </p>
              <br />
              <br />

              <p className="hsb">THE CRYPTO CREW.</p>
              <br />
              <br />
              <div className="video_css">
                <video
                  style={{ width: "80%", borderRadius: "20px" }}
                  controls
                  className="video_css"
                >
                  <source src={video} type="video/mp4" />
                  <source src={video} type="video/ogg" />
                </video>
              </div>
              <br />
              <br />
              <br />
              <br />
              <div className="video_css">
                <p className="hsb"></p>
              </div>

              <br />

              <br />
              <div className="row">
                <div className="flex grid">
                  <div className="gradient-bottom"></div>
                </div>
              </div>
            </section>
          </main>
        </div>
      </SkeletonTheme>
    </div>
  );
};

export default UnderHead;
