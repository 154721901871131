import React, { Suspense, StrictMode } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import logo from "./cclogo.png";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
//  import 'swiper/css/bundle';
import reportWebVitals from "./reportWebVitals";
gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);
const root = ReactDOM.createRoot(document.getElementById("root"));
const stream = require("stream");


root.render(
  <StrictMode>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
