import React from "react";
import { Grid } from "@mui/material";
import Mana from "./image/ava.gif";
import fantom from "./image/biti.gif";
import ether from "./image/mati.gif";
import cro from "./image/cros.gif";


const Cards = () => {
  return (
    <div className="pad">
      <Grid container spacing={2}>
        <Grid item md={6} lg={3} xs={6} sm={6}>
          <div class="containerho">
            <img src={Mana} alt="" />
            <div class="overlay ">
              <span className="head_underz font_cards">Avalanche</span>
            </div>
          </div>
        </Grid>
        <Grid item md={6} lg={3} xs={6} sm={6}>
          <div class="containerho">
            <img src={fantom} alt="" />
            <div class="overlay">
              <span className="head_underz font_cards">Bitman</span>
            </div>
          </div>
        </Grid>
        <Grid item md={6} lg={3} xs={6} sm={6}>
          <div class="containerho">
            <img src={ether} alt="" />
            <div class="overlay">
              <span className="head_underz font_cards">Matic Man</span>
            </div>
          </div>
        </Grid>
        <Grid item md={6} lg={3} xs={6} sm={6}>
          <div class="containerho">
            <img src={cro} alt="" />
            <div class="overlay">
              <span className="head_underz font_cards">Cro</span>
            </div>
          </div>
        </Grid>

    
       
      </Grid>
    </div>
  );
};

export default Cards;
