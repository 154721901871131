import { useRef } from "react";
import {
  FaBars,
  FaTimes,
  FaDiscord,
  FaInstagram,
  FaTwitter,
  FaTelegramPlane,
} from "react-icons/fa";
import "./Navbar.css";
import logo from "./cclogo.png";
import discord from "./ico-discord.png";
import pdf from "./litepaper.pdf";

function Navbar() {
  const navRef = useRef();
  const showNavbar = () => {
    navRef.current.classList.toggle("responsive_nav");
  };

  return (
    <div baseColor="#482b94" highlightColor="#583e9e" className="head">
      {" "}
      <div className="gradient-top"></div>
      <div className="gradient-bottom"></div>
      <header>
        <a href="./">
          <img src={logo} style={{ width: "35%" }} />
        </a>
        <nav ref={navRef}>
          <a
            href="./"
            id="style-2"
            data-replace="Home"
            className="font_nav_text a"
          >
            <span>Home</span>
          </a> 
          <a
            href="/roadmap"
            id="style-2"
            data-replace="Roadmap"
            className="font_nav_text a"
          >
            <span>Roadmap</span>
          </a> 
          <a
            href="./#ourteam"
            id="style-2"
            data-replace=" Team"
            className="font_nav_text a"
          >
            <span> Team</span>
          </a> 
         

          <a
            href="./#set1"
            id="style-2"
            data-replace=" Set1"
            className="font_nav_text a"
          >
            <span> Set1</span>
          </a> 
          <a
            href="./#set2"
            id="style-2"
            data-replace=" Set2"
            className="font_nav_text a"
          >
            <span> Set2</span>
          </a> 

      
          <a
            href={pdf}
            target="_blank"
            id="style-2"
            data-replace="  Litepaper"
            className="font_nav_text a"
          >
            <span> Litepaper</span>
          </a> 
          <a
            href="./lore"
            id="style-2"
            data-replace="lore"
            className="font_nav_text a"
          >
            <span>Lore</span>
          </a>
          <a
            href="./merch"
            id="style-2"
            data-replace="Merch"
            className="font_nav_text a"
          >
            <span>Merch</span>
          </a>

         

          <a
            href="./harvest"
            id="style-2"
            data-replace="  Harvest"
            className="font_nav_text a"
          >
            <span> Harvest</span>
          </a> 

          <a
            href="./Gaming"
            id="style-2"
            data-replace="Polygon Heros"
            className="font_nav_text a"
          >
            <span>Polygon Heros</span>
          </a>
          
          <a
            href="./HeroRun"
            id="style-2"
            target="_blank"
            data-replace="Hero Run"
            className="font_nav_text a"
          >
            <span>Hero Run</span>
          </a>
         
          <button className="nav-btn nav-close-btn" onClick={showNavbar}>
            <FaTimes />
          </button>
          <div style={{ position: "relative", top: "5px" }}>
            <a
              href="http://discord.gg/crewmunity"
              className="font_nav_texts a"
            >
              <FaDiscord />
            </a>
            <a
              href="https://www.instagram.com/crypto._.crew/?igshid=YmMyMTA2M2Y="
              className="font_nav_texts a"
            >
              <FaInstagram />
            </a>
            <a
              href="https://twitter.com/cryptocrewnfts"
              className="font_nav_texts a"
            >
              <FaTwitter />
            </a>
            <a
              href="https://t.me/+U29SX3Tr4PNlMzUx"
              className="font_nav_texts a"
            >
              <FaTelegramPlane />
            </a>
          </div>
        </nav>
        <button className="nav-btn" onClick={showNavbar}>
          <FaBars />
        </button>
      </header>
    </div>
  );
}

export default Navbar;
