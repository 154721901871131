import React from "react";
import Header from "../NavBar/Navbar";
import Headers from "../NavBar/MobNav";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import HeroSection from "../component/HeroSection";
import UnderHeader from "../component/UnderHead";
import Roadmap from "../component/Roadmap";
import OurTeam from "../component/OurTeam";
import Set1 from "../component/Set";
import Set2 from "../component/Set2";
import Set3 from "../component/Set3";
import Store from "../component/Store";
import Partner from "../component/Partner";
import LetMe from "../component/LetMe";

import Footer from "../component/Footer";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
function Garden() {
  const theme = useTheme();
  const smallScreen = useMediaQuery((theme) => theme.breakpoints.down("lg"));
  return (
    <div className="garden">
      {/* THIS JAVASCRIPT CODE IS FOR Header of the Park Page*/}

      {/* <Slider /> */}
      {!smallScreen && (
        <div style={{ backgroundColor: " #160f22 " }}>
          <Header />
          <HeroSection />
          <UnderHeader />
          <OurTeam />
          <Set1 />
          <Set2 />
          <Set3 />
          <Store />
          <Partner />
          <Footer />
        </div>
      )}
      {/* THIS JAVASCRIPT CODE IS FOR the Slider used for the Desktop View when you press on CustomSlider with ctrl and right arrow you move towards the Slider Page Desktop View*/}
      {smallScreen && (
        <div style={{ backgroundColor: " #160f22 " }}>
          <Headers />
          <HeroSection />
          <UnderHeader />
          <OurTeam />

          <Set1 />
          <Set2 />
          <Set3 />
          <Store />
          <Partner />

          <Footer />
        </div>
      )}
      {/* THIS JAVASCRIPT CODE IS FOR the Slider used for the Mobile View when you press on MobileSlider with ctrl and right arrow you move towards the Slider Page Mobile View*/}
    </div>
  );
}

export default Garden;
