import React from "react";
import { FaTwitter } from "react-icons/fa";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Grid } from "@mui/material";
import elkf from "./image/elkf.png";
import punks from "./image/punks.jpg";
import wayc from "./image/wayc.png";
import rek from "./image/rek.png";
import zom from "./image/zom.jpg";
import grey from "./image/them.jpg";
import muta from "./image/muta.jpg";
import polb from "./image/polb.jpg";
import KnightPeasant from "./image/KnightPeasant.jpg";
import SkurpySocial from "./image/brain.jpg";
import BabyBulls from "./image/BabyBulls.jpg";
import LittlebitsWorld from "./image/LittlebitsWorld.jpg";
import brozo from "./image/brozo.png";
import siva from "./image/siva.jpg";
import billionaire from "./image/billionaire.jpg";
import Friday from "./image/Friday.jpg";
import guild from "./image/guild.jpg";
import maticnews from "./image/maticnews.jpg";
import PoweredByPoly from "./image/PoweredByPoly.jpg";
import SaltySharks from "./image/SaltySharks.jpg";
import FlightForce from "./image/FlightForce.jpg";
import peto from "./image/peto.png";
import Rabid from "./image/Rabid.jpg";
import escapeverse from "./image/escapeverse.jpg";
import space from "./image/space.jpg";
import TheJockeyExpc from "./image/TheJockeyExpc.jpg";
import meta from "./image/Meta.png";
import degoverse from "./image/degoverse.jpg";
import INFAMs from "./image/INFAM.jpg";
import freezerverse from "./image/freezerverse.jpg";
import forge21 from "./image/forge21.jpg";
import Community from "./image/Community.jpg";
import DaturiansNFT from "./image/DaturiansNFT.jpg";
import DuckSeasonNFT from "./image/DuckSeasonNFT.jpg";
import LostHopeSociety from "./image/LostHopeSociety.jpg";
import AngelAlliance from "./image/AngelAlliance.jpg";
import sos from "./image/sos.jpg";
import turuverse from "./image/turuverse.jpg";
import SignedLLC from "./image/SignedLLC.jpg";
import gamiflyco from "./image/gamiflyco.jpg";
import GroofStreet from "./image/GroofStreet.jpg";
import VoxelXnetwork from "./image/VoxelXnetwork.jpg";
import GameTradeMarket from "./image/GameTradeMarket.png";
import HideousHackers from "./image/HideousHackers.jpg";
import NFCreeps from "./image/NFCreeps.jpg";
import HeroShoes from "./image/HeroShoes.jpg";
import Pachainkoio from "./image/Pachainkoio.jpg";

const PartnerCard = () => {
  return (
    <Grid container spacing={2} className="partner_card_pad">
      <Grid item lg={2} sm={6} xs={6} md={4}>
        {" "}
        <Card className="card_bg box_Par">
          <CardMedia
            sx={{ height: 130, width: 130 }}
            image={punks}
            title="PolygonPunks"
            className="picpa img_partner"
          />

          <CardContent>
            <Typography
              gutterBottom
              variant="h5"
              component="div"
              className="partner_text  "
            >
              PolygonPunks
            </Typography>
            <Typography variant="body2" color="text.secondary">
              <br />
              <a
                href="https://twitter.com/polygonpunkscom"
                className="font_nav_texts  head_underz"
                style={{ fontSize: "20px", color: "#fff" }}
              >
                <FaTwitter />
              </a>
            </Typography>
          </CardContent>
        </Card>
      </Grid>{" "}
      <Grid item lg={2} sm={6} xs={6} md={4}>
        {" "}
        <Card className="card_bg box_Par">
          <CardMedia
            sx={{ height: 130, width: 130 }}
            image={wayc}
            title=" Weary Ape
          Yacht Club"
            className="picpa img_partner"
          />

          <CardContent>
            <Typography
              gutterBottom
              variant="h5"
              component="div"
              className="partner_text"
            >
              Weary Ape Yacht Club
            </Typography>
            <Typography variant="body2" color="text.secondary">
              <br />
              <a
                href="https://twitter.com/WearyClub"
                className="font_nav_texts  head_underz"
                style={{ fontSize: "20px" }}
              >
                <FaTwitter />
              </a>
            </Typography>
          </CardContent>
        </Card>
      </Grid>{" "}
      <Grid item lg={2} sm={6} xs={6} md={4}>
        {" "}
        <Card className="card_bg box_Par">
          <CardMedia
            sx={{ height: 130, width: 130 }}
            image={rek}
            title=" Rekt Gang"
            className="picpa img_partner"
          />

          <CardContent>
            <Typography
              gutterBottom
              variant="h5"
              component="div"
              className="partner_text"
            >
              Rekt Gang
            </Typography>
            <Typography variant="body2" color="text.secondary">
              <br />
              <a
                href="https://twitter.com/rekt_gang"
                className="font_nav_texts  head_underz"
                style={{ fontSize: "20px" }}
              >
                <FaTwitter />
              </a>
            </Typography>
          </CardContent>
        </Card>
      </Grid>{" "}
      <Grid item lg={2} sm={6} xs={6} md={4}>
        {" "}
        <Card className="card_bg box_Par">
          <CardMedia
            sx={{ height: 130, width: 130 }}
            image={zom}
            title="  Billionaire Zombies Club"
            className="picpa img_partner"
            style={{ borderRadius: "50%" }}
          />

          <CardContent>
            <Typography
              gutterBottom
              variant="h5"
              component="div"
              className="partner_text"
            >
              Billionaire Zombies Club
            </Typography>
            <Typography variant="body2" color="text.secondary">
              <br />
              <a
                href="https://twitter.com/bzombiesc"
                className="font_nav_texts  head_underz"
                style={{ fontSize: "20px" }}
              >
                <FaTwitter />
              </a>
            </Typography>
          </CardContent>
        </Card>
      </Grid>{" "}
      <Grid item lg={2} sm={6} xs={6} md={4}>
        {" "}
        <Card className="card_bg box_Par">
          <CardMedia
            sx={{ height: 130, width: 130 }}
            image={grey}
            title="  The Matic Greys"
            className="picpa img_partner"
            style={{ borderRadius: "50%" }}
          />

          <CardContent>
            <Typography
              gutterBottom
              variant="h5"
              component="div"
              className="partner_text"
            >
              The Matic Greys
            </Typography>
            <Typography variant="body2" color="text.secondary">
              <br />
              <a
                href="https://twitter.com/TheMaticGreys"
                className="font_nav_texts  head_underz"
                style={{ fontSize: "20px" }}
              >
                <FaTwitter />
              </a>
            </Typography>
          </CardContent>
        </Card>
      </Grid>{" "}
      <Grid item lg={2} sm={6} xs={6} md={4}>
        {" "}
        <Card className="card_bg box_Par">
          <CardMedia
            sx={{ height: 130, width: 130 }}
            image={muta}
            title="MutantZ"
            className="picpa img_partner"
            style={{ borderRadius: "50%" }}
          />

          <CardContent>
            <Typography
              gutterBottom
              variant="h5"
              component="div"
              className="partner_text"
            >
              MutantZ
            </Typography>
            <Typography variant="body2" color="text.secondary">
              <br />
              <a
                href="https://twitter.com/wearethemutantz"
                className="font_nav_texts  head_underz"
                style={{ fontSize: "20px" }}
              >
                <FaTwitter />
              </a>
            </Typography>
          </CardContent>
        </Card>
      </Grid>{" "}
      <Grid item lg={2} sm={6} xs={6} md={4}>
        {" "}
        <Card className="card_bg box_Par">
          <CardMedia
            sx={{ height: 130, width: 130 }}
            image={polb}
            title="Polygon Boyz"
            className="picpa img_partner"
            style={{ borderRadius: "50%" }}
          />

          <CardContent>
            <Typography
              gutterBottom
              variant="h5"
              component="div"
              className="partner_text"
            >
              Polygon Boyz
            </Typography>
            <Typography variant="body2" color="text.secondary">
              <br />
              <a
                href="https://twitter.com/PolygonBoyz"
                className="font_nav_texts  head_underz"
                style={{ fontSize: "20px" }}
              >
                <FaTwitter />
              </a>
            </Typography>
          </CardContent>
        </Card>
      </Grid>{" "}
      <Grid item lg={2} sm={6} xs={6} md={4}>
        {" "}
        <Card className="card_bg box_Par">
          <CardMedia
            sx={{ height: 130, width: 130 }}
            image={KnightPeasant}
            title="KnightPeasant"
            className="picpa img_partner"
            style={{ borderRadius: "50%" }}
          />

          <CardContent>
            <Typography
              gutterBottom
              variant="h5"
              component="div"
              className="partner_text"
            >
              Knights & Peasants
            </Typography>
            <Typography variant="body2" color="text.secondary">
              <br />
              <a
                href="https://twitter.com/KnightPeasants"
                className="font_nav_texts  head_underz"
                style={{ fontSize: "20px" }}
              >
                <FaTwitter />
              </a>
            </Typography>
          </CardContent>
        </Card>
      </Grid>{" "}
      <Grid item lg={2} sm={6} xs={6} md={4}>
        {" "}
        <Card className="card_bg box_Par">
          <CardMedia
            sx={{ height: 130, width: 130 }}
            image={SkurpySocial}
            title="Brainy Budz"
            className="picpa img_partner"
            style={{ borderRadius: "50%" }}
          />

          <CardContent>
            <Typography
              gutterBottom
              variant="h5"
              component="div"
              className="partner_text"
            >
              Brainy Budz
            </Typography>
            <Typography variant="body2" color="text.secondary">
              <br />
              <a
                href="https://twitter.com/BrainyBudz420"
                className="font_nav_texts  head_underz"
                style={{ fontSize: "20px" }}
              >
                <FaTwitter />
              </a>
            </Typography>
          </CardContent>
        </Card>
      </Grid>{" "}
      <Grid item lg={2} sm={6} xs={6} md={4}>
        {" "}
        <Card className="card_bg box_Par">
          <CardMedia
            sx={{ height: 130, width: 130 }}
            image={BabyBulls}
            title="Baby Bulls"
            className="picpa img_partner"
            style={{ borderRadius: "50%" }}
          />

          <CardContent>
            <Typography
              gutterBottom
              variant="h5"
              component="div"
              className="partner_text"
            >
              Baby Bulls
            </Typography>
            <Typography variant="body2" color="text.secondary">
              <br />
              <a
                href="https://twitter.com/BabyBulls_"
                className="font_nav_texts  head_underz"
                style={{ fontSize: "20px" }}
              >
                <FaTwitter />
              </a>
            </Typography>
          </CardContent>
        </Card>
      </Grid>{" "}
      <Grid item lg={2} sm={6} xs={6} md={4}>
        {" "}
        <Card className="card_bg box_Par">
          <CardMedia
            sx={{ height: 130, width: 130 }}
            image={LittlebitsWorld}
            title="  The Matic Greys"
            className="picpa img_partner"
            style={{ borderRadius: "50%" }}
          />

          <CardContent>
            <Typography
              gutterBottom
              variant="h5"
              component="div"
              className="partner_text"
            >
              Littlebits World
            </Typography>
            <Typography variant="body2" color="text.secondary">
              <br />
              <a
                href="https://twitter.com/LittlebitsNFT"
                className="font_nav_texts  head_underz"
                style={{ fontSize: "20px" }}
              >
                <FaTwitter />
              </a>
            </Typography>
          </CardContent>
        </Card>
      </Grid>{" "}
      <Grid item lg={2} sm={6} xs={6} md={4}>
        {" "}
        <Card className="card_bg box_Par">
          <CardMedia
            sx={{ height: 130, width: 130 }}
            image={brozo}
            title="brozo"
            className="picpa img_partner"
            style={{ borderRadius: "50%" }}
          />

          <CardContent>
            <Typography
              gutterBottom
              variant="h5"
              component="div"
              className="partner_text"
            >
              Brozo
            </Typography>
            <Typography variant="body2" color="text.secondary">
              <br />
              <a
                href="https://twitter.com/brozonft?lang=en"
                className="font_nav_texts  head_underz"
                style={{ fontSize: "20px" }}
              >
                <FaTwitter />
              </a>
            </Typography>
          </CardContent>
        </Card>
      </Grid>{" "}
      <Grid item lg={2} sm={6} xs={6} md={4}>
        {" "}
        <Card className="card_bg box_Par">
          <CardMedia
            sx={{ height: 130, width: 130 }}
            image={siva}
            title="Siva"
            className="picpa img_partner"
            style={{ borderRadius: "50%" }}
          />

          <CardContent>
            <Typography
              gutterBottom
              variant="h5"
              component="div"
              className="partner_text"
            >
              Siva
            </Typography>
            <Typography variant="body2" color="text.secondary">
              <br />
              <a
                href="https://twitter.com/sivacoin"
                className="font_nav_texts  head_underz"
                style={{ fontSize: "20px" }}
              >
                <FaTwitter />
              </a>
            </Typography>
          </CardContent>
        </Card>
      </Grid>{" "}
      <Grid item lg={2} sm={6} xs={6} md={4}>
        {" "}
        <Card className="card_bg box_Par">
          <CardMedia
            sx={{ height: 130, width: 130 }}
            image={billionaire}
            title="Billionaire Fox"
            className="picpa img_partner"
            style={{ borderRadius: "50%" }}
          />

          <CardContent>
            <Typography
              gutterBottom
              variant="h5"
              component="div"
              className="partner_text"
            >
              Billionaire Fox
            </Typography>
            <Typography variant="body2" color="text.secondary">
              <br />
              <a
                href="https://twitter.com/billionaire_fox"
                className="font_nav_texts  head_underz"
                style={{ fontSize: "20px" }}
              >
                <FaTwitter />
              </a>
            </Typography>
          </CardContent>
        </Card>
      </Grid>{" "}
      <Grid item lg={2} sm={6} xs={6} md={4}>
        {" "}
        <Card className="card_bg box_Par">
          <CardMedia
            sx={{ height: 130, width: 130 }}
            image={Friday}
            title="Friday Night Punks"
            className="picpa img_partner"
            style={{ borderRadius: "50%" }}
          />

          <CardContent>
            <Typography
              gutterBottom
              variant="h5"
              component="div"
              className="partner_text"
            >
              Friday Night Punks
            </Typography>
            <Typography variant="body2" color="text.secondary">
              <br />
              <a
                href="https://twitter.com/FridayNightPunk"
                className="font_nav_texts  head_underz"
                style={{ fontSize: "20px" }}
              >
                <FaTwitter />
              </a>
            </Typography>
          </CardContent>
        </Card>
      </Grid>{" "}
      <Grid item lg={2} sm={6} xs={6} md={4}>
        {" "}
        <Card className="card_bg box_Par">
          <CardMedia
            sx={{ height: 130, width: 130 }}
            image={guild}
            title="315 Guild"
            className="picpa img_partner"
            style={{ borderRadius: "50%" }}
          />

          <CardContent>
            <Typography
              gutterBottom
              variant="h5"
              component="div"
              className="partner_text"
            >
              315 Guild
            </Typography>
            <Typography variant="body2" color="text.secondary">
              <br />
              <a
                href="https://twitter.com/315Guild"
                className="font_nav_texts  head_underz"
                style={{ fontSize: "20px" }}
              >
                <FaTwitter />
              </a>
            </Typography>
          </CardContent>
        </Card>
      </Grid>{" "}
      <Grid item lg={2} sm={6} xs={6} md={4}>
        {" "}
        <Card className="card_bg box_Par">
          <CardMedia
            sx={{ height: 130, width: 130 }}
            image={maticnews}
            title="  Matic News"
            className="picpa img_partner"
            style={{ borderRadius: "50%" }}
          />

          <CardContent>
            <Typography
              gutterBottom
              variant="h5"
              component="div"
              className="partner_text"
            >
              Matic News
            </Typography>
            <Typography variant="body2" color="text.secondary">
              <br />
              <a
                href="https://twitter.com/maticnews_com"
                className="font_nav_texts  head_underz"
                style={{ fontSize: "20px" }}
              >
                <FaTwitter />
              </a>
            </Typography>
          </CardContent>
        </Card>
      </Grid>{" "}
      <Grid item lg={2} sm={6} xs={6} md={4}>
        {" "}
        <Card className="card_bg box_Par">
          <CardMedia
            sx={{ height: 130, width: 130 }}
            image={PoweredByPoly}
            title="Powered By Polygon"
            className="picpa img_partner"
            style={{ borderRadius: "50%" }}
          />

          <CardContent>
            <Typography
              gutterBottom
              variant="h5"
              component="div"
              className="partner_text"
            >
              Powered By Polygon
            </Typography>
            <Typography variant="body2" color="text.secondary">
              <br />
              <a
                href="https://twitter.com/PoweredByPoly"
                className="font_nav_texts  head_underz"
                style={{ fontSize: "20px" }}
              >
                <FaTwitter />
              </a>
            </Typography>
          </CardContent>
        </Card>
      </Grid>{" "}
      <Grid item lg={2} sm={6} xs={6} md={4}>
        {" "}
        <Card className="card_bg box_Par">
          <CardMedia
            sx={{ height: 130, width: 130 }}
            image={Community}
            title="Community Finance"
            className="picpa img_partner"
            style={{ borderRadius: "50%" }}
          />

          <CardContent>
            <Typography
              gutterBottom
              variant="h5"
              component="div"
              className="partner_text"
            >
              Community Finance
            </Typography>
            <Typography variant="body2" color="text.secondary">
              <br />
              <a
                href="https://twitter.com/Community0x"
                className="font_nav_texts  head_underz"
                style={{ fontSize: "20px" }}
              >
                <FaTwitter />
              </a>
            </Typography>
          </CardContent>
        </Card>
      </Grid>{" "}
      <Grid item lg={2} sm={6} xs={6} md={4}>
        {" "}
        <Card className="card_bg box_Par">
          <CardMedia
            sx={{ height: 130, width: 130 }}
            image={DaturiansNFT}
            title="Daturians"
            className="picpa img_partner"
            style={{ borderRadius: "50%" }}
          />

          <CardContent>
            <Typography
              gutterBottom
              variant="h5"
              component="div"
              className="partner_text"
            >
              Daturians
            </Typography>
            <Typography variant="body2" color="text.secondary">
              <br />
              <a
                href="https://twitter.com/DaturiansNFT/with_replies"
                className="font_nav_texts  head_underz"
                style={{ fontSize: "20px" }}
              >
                <FaTwitter />
              </a>
            </Typography>
          </CardContent>
        </Card>
      </Grid>{" "}
      <Grid item lg={2} sm={6} xs={6} md={4}>
        {" "}
        <Card className="card_bg box_Par">
          <CardMedia
            sx={{ height: 130, width: 130 }}
            image={DuckSeasonNFT}
            title="Duck Season"
            className="picpa img_partner"
            style={{ borderRadius: "50%" }}
          />

          <CardContent>
            <Typography
              gutterBottom
              variant="h5"
              component="div"
              className="partner_text"
            >
              Duck Season
            </Typography>
            <Typography variant="body2" color="text.secondary">
              <br />
              <a
                href="https://twitter.com/DuckSeasonNFT"
                className="font_nav_texts  head_underz"
                style={{ fontSize: "20px" }}
              >
                <FaTwitter />
              </a>
            </Typography>
          </CardContent>
        </Card>
      </Grid>{" "}
      <Grid item lg={2} sm={6} xs={6} md={4}>
        {" "}
        <Card className="card_bg box_Par">
          <CardMedia
            sx={{ height: 130, width: 130 }}
            image={AngelAlliance}
            title="Angel Alliance"
            className="picpa img_partner"
            style={{ borderRadius: "50%" }}
          />

          <CardContent>
            <Typography
              gutterBottom
              variant="h5"
              component="div"
              className="partner_text"
            >
              Angel Alliance
            </Typography>
            <Typography variant="body2" color="text.secondary">
              <br />
              <a
                href="https://twitter.com/AngelAlliance"
                className="font_nav_texts  head_underz"
                style={{ fontSize: "20px" }}
              >
                <FaTwitter />
              </a>
            </Typography>
          </CardContent>
        </Card>
      </Grid>{" "}
      <Grid item lg={2} sm={6} xs={6} md={4}>
        {" "}
        <Card className="card_bg box_Par">
          <CardMedia
            sx={{ height: 130, width: 130 }}
            image={sos}
            title="SOS Kids"
            className="picpa img_partner"
            style={{ borderRadius: "50%" }}
          />

          <CardContent>
            <Typography
              gutterBottom
              variant="h5"
              component="div"
              className="partner_text"
            >
              SOS Kids
            </Typography>
            <Typography variant="body2" color="text.secondary">
              <br />
              <a
                href="https://twitter.com/soskids22"
                className="font_nav_texts  head_underz"
                style={{ fontSize: "20px" }}
              >
                <FaTwitter />
              </a>
            </Typography>
          </CardContent>
        </Card>
      </Grid>{" "}
      <Grid item lg={2} sm={6} xs={6} md={4}>
        {" "}
        <Card className="card_bg box_Par">
          <CardMedia
            sx={{ height: 130, width: 130 }}
            image={SaltySharks}
            title="Salty Sharks"
            className="picpa img_partner"
            style={{ borderRadius: "50%" }}
          />

          <CardContent>
            <Typography
              gutterBottom
              variant="h5"
              component="div"
              className="partner_text"
            >
              Salty Sharks
            </Typography>
            <Typography variant="body2" color="text.secondary">
              <br />
              <a
                href="https://twitter.com/SaltySharksNFT"
                className="font_nav_texts  head_underz"
                style={{ fontSize: "20px" }}
              >
                <FaTwitter />
              </a>
            </Typography>
          </CardContent>
        </Card>
      </Grid>{" "}
      <Grid item lg={2} sm={6} xs={6} md={4}>
        {" "}
        <Card className="card_bg box_Par">
          <CardMedia
            sx={{ height: 130, width: 130 }}
            image={FlightForce}
            title="Flight Force 4"
            className="picpa img_partner"
            style={{ borderRadius: "50%" }}
          />

          <CardContent>
            <Typography
              gutterBottom
              variant="h5"
              component="div"
              className="partner_text"
            >
              Flight Force 4
            </Typography>
            <Typography variant="body2" color="text.secondary">
              <br />
              <a
                href="https://twitter.com/FlightForce4"
                className="font_nav_texts  head_underz"
                style={{ fontSize: "20px" }}
              >
                <FaTwitter />
              </a>
            </Typography>
          </CardContent>
        </Card>
      </Grid>{" "}
      <Grid item lg={2} sm={6} xs={6} md={4}>
        {" "}
        <Card className="card_bg box_Par">
          <CardMedia
            sx={{ height: 130, width: 130 }}
            image={peto}
            title="Petto"
            className="picpa img_partner"
            style={{ borderRadius: "50%" }}
          />

          <CardContent>
            <Typography
              gutterBottom
              variant="h5"
              component="div"
              className="partner_text"
            >
              Petto
            </Typography>
            <Typography variant="body2" color="text.secondary">
              <br />
              <a
                href="https://twitter.com/NFTMintRadar"
                className="font_nav_texts  head_underz"
                style={{ fontSize: "20px" }}
              >
                <FaTwitter />
              </a>
            </Typography>
          </CardContent>
        </Card>
      </Grid>{" "}
      <Grid item lg={2} sm={6} xs={6} md={4}>
        {" "}
        <Card className="card_bg box_Par">
          <CardMedia
            sx={{ height: 130, width: 130 }}
            image={Rabid}
            title="Rabid"
            className="picpa img_partner"
            style={{ borderRadius: "50%" }}
          />

          <CardContent>
            <Typography
              gutterBottom
              variant="h5"
              component="div"
              className="partner_text"
            >
              Rabid
            </Typography>
            <Typography variant="body2" color="text.secondary">
              <br />
              <a
                href="https://twitter.com/Rabid_DAO"
                className="font_nav_texts  head_underz"
                style={{ fontSize: "20px" }}
              >
                <FaTwitter />
              </a>
            </Typography>
          </CardContent>
        </Card>
      </Grid>{" "}
      <Grid item lg={2} sm={6} xs={6} md={4}>
        {" "}
        <Card className="card_bg box_Par">
          <CardMedia
            sx={{ height: 130, width: 130 }}
            image={escapeverse}
            title="The EscapeVerse"
            className="picpa img_partner"
            style={{ borderRadius: "50%" }}
          />

          <CardContent>
            <Typography
              gutterBottom
              variant="h5"
              component="div"
              className="partner_text"
            >
              The EscapeVerse
            </Typography>
            <Typography variant="body2" color="text.secondary">
              <br />
              <a
                href="https://twitter.com/TheEscapeVerse?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor"
                className="font_nav_texts  head_underz"
                style={{ fontSize: "20px" }}
              >
                <FaTwitter />
              </a>
            </Typography>
          </CardContent>
        </Card>
      </Grid>{" "}
      <Grid item lg={2} sm={6} xs={6} md={4}>
        {" "}
        <Card className="card_bg box_Par">
          <CardMedia
            sx={{ height: 130, width: 130 }}
            image={space}
            title="Space Skellies"
            className="picpa img_partner"
            style={{ borderRadius: "50%" }}
          />

          <CardContent>
            <Typography
              gutterBottom
              variant="h5"
              component="div"
              className="partner_text"
            >
              Space Skellies
            </Typography>
            <Typography variant="body2" color="text.secondary">
              <br />
              <a
                href="https://twitter.com/spaceskellies"
                className="font_nav_texts  head_underz"
                style={{ fontSize: "20px" }}
              >
                <FaTwitter />
              </a>
            </Typography>
          </CardContent>
        </Card>
      </Grid>{" "}
      <Grid item lg={2} sm={6} xs={6} md={4}>
        {" "}
        <Card className="card_bg box_Par">
          <CardMedia
            sx={{ height: 130, width: 130 }}
            image={TheJockeyExpc}
            title="The Jockey Experience"
            className="picpa img_partner"
            style={{ borderRadius: "50%" }}
          />

          <CardContent>
            <Typography
              gutterBottom
              variant="h5"
              component="div"
              className="partner_text"
            >
              The Jockey Experience
            </Typography>
            <Typography variant="body2" color="text.secondary">
              <br />
              <a
                href="https://twitter.com/TheJockeyExpc"
                className="font_nav_texts  head_underz"
                style={{ fontSize: "20px" }}
              >
                <FaTwitter />
              </a>
            </Typography>
          </CardContent>
        </Card>
      </Grid>{" "}
      <Grid item lg={2} sm={6} xs={6} md={4}>
        {" "}
        <Card className="card_bg box_Par">
          <CardMedia
            sx={{ height: 130, width: 130 }}
            image={meta}
            title="Meta Mystic Superheroes"
            className="picpa img_partner"
            style={{ borderRadius: "50%" }}
          />

          <CardContent>
            <Typography
              gutterBottom
              variant="h5"
              component="div"
              className="partner_text"
            >
              Meta Mystic Superheroes
            </Typography>
            <Typography variant="body2" color="text.secondary">
              <br />
              <a
                href="https://metamysticsuperheroes.com/"
                className="font_nav_texts  head_underz"
                style={{ fontSize: "20px" }}
              >
                <FaTwitter />
              </a>
            </Typography>
          </CardContent>
        </Card>
      </Grid>{" "}
      <Grid item lg={2} sm={6} xs={6} md={4}>
        {" "}
        <Card className="card_bg box_Par">
          <CardMedia
            sx={{ height: 130, width: 130 }}
            image={degoverse}
            title="Degoverse"
            className="picpa img_partner"
            style={{ borderRadius: "50%" }}
          />

          <CardContent>
            <Typography
              gutterBottom
              variant="h5"
              component="div"
              className="partner_text"
            >
              Degoverse
            </Typography>
            <Typography variant="body2" color="text.secondary">
              <br />
              <a
                href="https://twitter.com/degoverse"
                className="font_nav_texts  head_underz"
                style={{ fontSize: "20px" }}
              >
                <FaTwitter />
              </a>
            </Typography>
          </CardContent>
        </Card>
      </Grid>{" "}
      <Grid item lg={2} sm={6} xs={6} md={4}>
        {" "}
        <Card className="card_bg box_Par">
          <CardMedia
            sx={{ height: 130, width: 130 }}
            image={INFAMs}
            title="INFAM"
            className="picpa img_partner"
            style={{ borderRadius: "50%" }}
          />

          <CardContent>
            <Typography
              gutterBottom
              variant="h5"
              component="div"
              className="partner_text"
            >
              INFAM
            </Typography>
            <Typography variant="body2" color="text.secondary">
              <br />
              <a
                href="https://twitter.com/Infam_platform"
                className="font_nav_texts  head_underz"
                style={{ fontSize: "20px" }}
              >
                <FaTwitter />
              </a>
            </Typography>
          </CardContent>
        </Card>
      </Grid>{" "}
      <Grid item lg={2} sm={6} xs={6} md={4}>
        {" "}
        <Card className="card_bg box_Par">
          <CardMedia
            sx={{ height: 130, width: 130 }}
            image={freezerverse}
            title="Freezer Verse"
            className="picpa img_partner"
            style={{ borderRadius: "50%" }}
          />

          <CardContent>
            <Typography
              gutterBottom
              variant="h5"
              component="div"
              className="partner_text"
            >
              Freezer Verse
            </Typography>
            <Typography variant="body2" color="text.secondary">
              <br />
              <a
                href="https://twitter.com/freezerverse"
                className="font_nav_texts  head_underz"
                style={{ fontSize: "20px" }}
              >
                <FaTwitter />
              </a>
            </Typography>
          </CardContent>
        </Card>
      </Grid>{" "}
      <Grid item lg={2} sm={6} xs={6} md={4}>
        {" "}
        <Card className="card_bg box_Par">
          <CardMedia
            sx={{ height: 130, width: 130 }}
            image={forge21}
            title="Space Skellies"
            className="picpa img_partner"
            style={{ borderRadius: "50%" }}
          />

          <CardContent>
            <Typography
              gutterBottom
              variant="h5"
              component="div"
              className="partner_text"
            >
              Forge 21 Studios
            </Typography>
            <Typography variant="body2" color="text.secondary">
              <br />
              <a
                href="https://twitter.com/forge21official?lang=en"
                className="font_nav_texts  head_underz"
                style={{ fontSize: "20px" }}
              >
                <FaTwitter />
              </a>
            </Typography>
          </CardContent>
        </Card>
      </Grid>{" "}
      <Grid item lg={2} sm={6} xs={6} md={4}>
        {" "}
        <Card className="card_bg box_Par">
          <CardMedia
            sx={{ height: 130, width: 130 }}
            image={turuverse}
            title="TuruVerse"
            className="picpa img_partner"
            style={{ borderRadius: "50%" }}
          />

          <CardContent>
            <Typography
              gutterBottom
              variant="h5"
              component="div"
              className="partner_text"
            >
              TuruVerse
            </Typography>
            <Typography variant="body2" color="text.secondary">
              <br />
              <a
                href="https://twitter.com/TuruMetaverse"
                className="font_nav_texts  head_underz"
                style={{ fontSize: "20px" }}
              >
                <FaTwitter />
              </a>
            </Typography>
          </CardContent>
        </Card>
      </Grid>{" "}
      <Grid item lg={2} sm={6} xs={6} md={4}>
        {" "}
        <Card className="card_bg box_Par">
          <CardMedia
            sx={{ height: 130, width: 130 }}
            image={SignedLLC}
            title="Signed.Trade"
            className="picpa img_partner"
            style={{ borderRadius: "50%" }}
          />

          <CardContent>
            <Typography
              gutterBottom
              variant="h5"
              component="div"
              className="partner_text"
            >
              Signed.Trade
            </Typography>
            <Typography variant="body2" color="text.secondary">
              <br />
              <a
                href="https://twitter.com/SignedLLC"
                className="font_nav_texts  head_underz"
                style={{ fontSize: "20px" }}
              >
                <FaTwitter />
              </a>
            </Typography>
          </CardContent>
        </Card>
      </Grid>{" "}
      <Grid item lg={2} sm={6} xs={6} md={4}>
        {" "}
        <Card className="card_bg box_Par">
          <CardMedia
            sx={{ height: 130, width: 130 }}
            image={gamiflyco}
            title="Gamifly"
            className="picpa img_partner"
            style={{ borderRadius: "50%" }}
          />

          <CardContent>
            <Typography
              gutterBottom
              variant="h5"
              component="div"
              className="partner_text"
            >
              Gamifly
            </Typography>
            <Typography variant="body2" color="text.secondary">
              <br />
              <a
                href="https://twitter.com/gamiflyco"
                className="font_nav_texts  head_underz"
                style={{ fontSize: "20px" }}
              >
                <FaTwitter />
              </a>
            </Typography>
          </CardContent>
        </Card>
      </Grid>{" "}
      <Grid item lg={2} sm={6} xs={6} md={4}>
        {" "}
        <Card className="card_bg box_Par">
          <CardMedia
            sx={{ height: 130, width: 130 }}
            image={GroofStreet}
            title="Groof Street"
            className="picpa img_partner"
            style={{ borderRadius: "50%" }}
          />

          <CardContent>
            <Typography
              gutterBottom
              variant="h5"
              component="div"
              className="partner_text"
            >
              Groof Street
            </Typography>
            <Typography variant="body2" color="text.secondary">
              <br />
              <a
                href="https://twitter.com/GroofStreet"
                className="font_nav_texts  head_underz"
                style={{ fontSize: "20px" }}
              >
                <FaTwitter />
              </a>
            </Typography>
          </CardContent>
        </Card>
      </Grid>{" "}
      <Grid item lg={2} sm={6} xs={6} md={4}>
        {" "}
        <Card className="card_bg box_Par">
          <CardMedia
            sx={{ height: 130, width: 130 }}
            image={VoxelXnetwork}
            title="Voxel X"
            className="picpa img_partner"
            style={{ borderRadius: "50%" }}
          />

          <CardContent>
            <Typography
              gutterBottom
              variant="h5"
              component="div"
              className="partner_text"
            >
              Voxel X
            </Typography>
            <Typography variant="body2" color="text.secondary">
              <br />
              <a
                href="https://twitter.com/VoxelXnetwork"
                className="font_nav_texts  head_underz"
                style={{ fontSize: "20px" }}
              >
                <FaTwitter />
              </a>
            </Typography>
          </CardContent>
        </Card>
      </Grid>{" "}
      <Grid item lg={2} sm={6} xs={6} md={4}>
        {" "}
        <Card className="card_bg box_Par">
          <CardMedia
            sx={{ height: 130, width: 130 }}
            image={GameTradeMarket}
            title="Game Trade"
            className="picpa img_partner"
            style={{ borderRadius: "50%" }}
          />

          <CardContent>
            <Typography
              gutterBottom
              variant="h5"
              component="div"
              className="partner_text"
            >
              Game Trade
            </Typography>
            <Typography variant="body2" color="text.secondary">
              <br />
              <a
                href="https://twitter.com/GameTradeMarket"
                className="font_nav_texts  head_underz"
                style={{ fontSize: "20px" }}
              >
                <FaTwitter />
              </a>
            </Typography>
          </CardContent>
        </Card>
      </Grid>{" "}
      <Grid item lg={2} sm={6} xs={6} md={4}>
        {" "}
        <Card className="card_bg box_Par">
          <CardMedia
            sx={{ height: 130, width: 130 }}
            image={HideousHackers}
            title="Hideous Hackers"
            className="picpa img_partner"
            style={{ borderRadius: "50%" }}
          />

          <CardContent>
            <Typography
              gutterBottom
              variant="h5"
              component="div"
              className="partner_text"
            >
              Hideous Hackers
            </Typography>
            <Typography variant="body2" color="text.secondary">
              <br />
              <a
                href="https://twitter.com/HideousHackers"
                className="font_nav_texts  head_underz"
                style={{ fontSize: "20px" }}
              >
                <FaTwitter />
              </a>
            </Typography>
          </CardContent>
        </Card>
      </Grid>{" "}
      <Grid item lg={2} sm={6} xs={6} md={4}>
        {" "}
        <Card className="card_bg box_Par">
          <CardMedia
            sx={{ height: 130, width: 130 }}
            image={NFCreeps}
            title="NFCreeps"
            className="picpa img_partner"
            style={{ borderRadius: "50%" }}
          />

          <CardContent>
            <Typography
              gutterBottom
              variant="h5"
              component="div"
              className="partner_text"
            >
              NFCreeps
            </Typography>
            <Typography variant="body2" color="text.secondary">
              <br />
              <a
                href="https://twitter.com/NFCreeps"
                className="font_nav_texts  head_underz"
                style={{ fontSize: "20px" }}
              >
                <FaTwitter />
              </a>
            </Typography>
          </CardContent>
        </Card>
      </Grid>{" "}
      <Grid item lg={2} sm={6} xs={6} md={4}>
        {" "}
        <Card className="card_bg box_Par">
          <CardMedia
            sx={{ height: 130, width: 130 }}
            image={HeroShoes}
            title="Hero Shoes NFT"
            className="picpa img_partner"
            style={{ borderRadius: "50%" }}
          />

          <CardContent>
            <Typography
              gutterBottom
              variant="h5"
              component="div"
              className="partner_text"
            >
              Hero Shoes NFT
            </Typography>
            <Typography variant="body2" color="text.secondary">
              <br />
              <a
                href="https://twitter.com/HeroShoes_NFT"
                className="font_nav_texts  head_underz"
                style={{ fontSize: "20px" }}
              >
                <FaTwitter />
              </a>
            </Typography>
          </CardContent>
        </Card>
      </Grid>{" "}
      <Grid item lg={2} sm={6} xs={6} md={4}>
        {" "}
        <Card className="card_bg box_Par">
          <CardMedia
            sx={{ height: 130, width: 130 }}
            image={Pachainkoio}
            title="Pachainko"
            className="picpa img_partner"
            style={{ borderRadius: "50%" }}
          />

          <CardContent>
            <Typography
              gutterBottom
              variant="h5"
              component="div"
              className="partner_text"
            >
              Pachainko
            </Typography>
            <Typography variant="body2" color="text.secondary">
              <br />
              <a
                href="https://twitter.com/Pachainkoio"
                className="font_nav_texts  head_underz"
                style={{ fontSize: "20px" }}
              >
                <FaTwitter />
              </a>
            </Typography>
          </CardContent>
        </Card>
      </Grid>{" "}
    </Grid>
  );
};

export default PartnerCard;
