import React, { useRef, useState } from "react";
// Import Swiper React components

// Import Swiper styles

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";

import "./styles.css";

import matic from "./image/maticman.png";
import yup from "./image/yup.png";
import brian from "./image/brian.png";
import brianbg from "./image/brbg.png";
import mana from "./image/mana.png";
import corey from "./image/corey.png";
import victoria from "./image/victoria.png";
import max from "./image/max.png";
import sto from "./image/sto.png";
import pre from "./image/pre.png";
import ada from "./image/ada.png";
// import required modules
import { EffectCoverflow, Pagination, Autoplay, Keyboard } from "swiper";

const TDSldier = () => {
  return (
    <div id="services">
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <div className="container">
        {" "}
        <>
          <>
            <Swiper
              effect={"coverflow"}
              loop={true}
              keyboard={{
                enabled: true,
              }}
              grabCursor={true}
              centeredSlides={true}
              slidesPerView={"auto"}
              coverflowEffect={{
                rotate: 50,
                stretch: 0,
                depth: 100,
                modifier: 1,
                slideShadows: true,
              }}
              freeMode={true}
              autoplay={{
                delay: 4500,
                disableOnInteraction: false,
              }}
              pagination={true}
              modules={[EffectCoverflow, Autoplay, Pagination, Keyboard]}
              className="mySwiper"
            >
              <SwiperSlide>
                <div>
                  {" "}
                  <a
                    href="https://twitter.com/thematicman"
                    alt="Mythrill"
                    target="_blank"
                  >
                    <br />

                    <div class="card">
                      <div class="wrapper">
                        <img src={matic} class="cover-image" />
                      </div>
                      <div className="text_div">
                        <h4 class="card_text">TONY</h4>

                        <h4>
                          <p className="card_under_text">
                            {" "}
                            (aka The MATIC MAN)
                            <br />
                            Founder / CEO
                          </p>
                        </h4>
                      </div>

                      <img src={matic} class="character" />
                    </div>
                  </a>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div>
                  {" "}
                  <a
                    href="https://twitter.com/YupThatsB_"
                    alt="Mythrill"
                    target="_blank"
                  >
                    <br />

                    <div class="card">
                      <div class="wrapper">
                        <img src={yup} class="cover-image" />
                      </div>
                      <div className="text_div">
                        <h4 class="card_text">YupthatsB</h4>

                        <h4>
                          <p className="card_under_text">
                            {" "}
                            (aka FANTOM) <br />
                            Chief Operations Officer
                          </p>
                        </h4>
                      </div>

                      <img src={yup} class="character" />
                    </div>
                  </a>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div>
                  {" "}
                  <a
                    href="https://twitter.com/Brianin802"
                    alt="Mythrill"
                    target="_blank"
                  >
                    <br />

                    <div class="card">
                      <div class="wrapper">
                        <img src={brian} class="cover-image" />
                      </div>
                      <div className="text_div">
                        <h4 class="card_text">Brian</h4>

                        <h4>
                          <p className="card_under_text">
                            {" "}
                            (aka BITMAN)
                            <br />
                            CTO/Blockchain Engineer
                          </p>
                        </h4>
                      </div>

                      <img src={brian} class="character" />
                    </div>
                  </a>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div>
                  {" "}
                  <a
                    href="https://twitter.com/lekrakenpv"
                    alt="Mythrill"
                    target="_blank"
                  >
                    <br />

                    <div class="card">
                      <div class="wrapper">
                        <img src={mana} class="cover-image" />
                      </div>
                      <div className="text_div">
                        <h4 class="card_text">Manny</h4>

                        <h4>
                          <p className="card_under_text">
                            {" "}
                            (aka MANA) <br />
                            Chief Marketing Officer (COO)
                          </p>
                        </h4>
                      </div>

                      <img src={mana} class="character" />
                    </div>
                  </a>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div>
                  {" "}
                  <a
                    href="https://twitter.com/cory_roberts16"
                    alt="Mythrill"
                    target="_blank"
                  >
                    <br />

                    <div class="card">
                      <div class="wrapper">
                        <img src={corey} class="cover-image" />
                      </div>
                      <div className="text_div">
                        <h4 class="card_text">Cory</h4>

                        <h4>
                          <p className="card_under_text">
                            {" "}
                            (aka ETHER)
                            <br />
                            Community Manager
                          </p>
                        </h4>
                      </div>

                      <img src={corey} class="character" />
                    </div>
                  </a>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div>
                  {" "}
                  <a
                    href="https://twitter.com/iamveektoria"
                    alt="Mythrill"
                    target="_blank"
                  >
                    <br />

                    <div class="card">
                      <div class="wrapper">
                        <img src={victoria} class="cover-image" />
                      </div>
                      <div className="text_div">
                        <h4 class="card_text">Veek</h4>

                        <h4>
                          <p className="card_under_text">
                            {" "}
                            (aka CRO)
                            <br />
                            Head of HEROES Program
                          </p>
                        </h4>
                      </div>

                      <img src={victoria} class="character" />
                    </div>
                  </a>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div>
                  {" "}
                  <a
                    href="https://twitter.com/MaxAcrone"
                    alt="Mythrill"
                    target="_blank"
                  >
                    <br />

                    <div class="card">
                      <div class="wrapper">
                        <img src={max} class="cover-image" />
                      </div>
                      <div className="text_div">
                        <h4 class="card_text">Max</h4>

                        <h4>
                          <p className="card_under_text">
                            {" "}
                            (aka SOL)
                            <br />
                            Head of Creative Design
                          </p>
                        </h4>
                      </div>

                      <img src={max} class="character" />
                    </div>
                  </a>
                </div>
              </SwiperSlide>

              <SwiperSlide>
                <div>
                  {" "}
                  <a
                    href="https://twitter.com/Pgm__x"
                    alt="Mythrill"
                    target="_blank"
                  >
                    <br />

                    <div class="card">
                      <div class="wrapper">
                        <img src={pre} class="cover-image" />
                      </div>
                      <div className="text_div">
                        <h4 class="card_text">PGM</h4>

                        <h4>
                          <p className="card_under_text">
                            {" "}
                            (aka XRP) <br />
                            Head Moderator
                          </p>
                        </h4>
                      </div>

                      <img src={pre} class="character" />
                    </div>
                  </a>
                </div>
              </SwiperSlide>
            </Swiper>
          </>
        </>
      </div>
    </div>
  );
};

export default TDSldier;
