import { useState, useCallback, useEffect, useContext } from "react";
import { providers, utils, Contract } from "ethers";
import { useAccount, useConnect, useDisconnect, useContract, useSigner } from 'wagmi';
import { getProvider, getNetwork } from '@wagmi/core';
import Moralis from 'moralis';
import CrewHarvest from "../contracts/CrewHarvest.json";
import CREWToken from "../contracts/CREWToken.json";
import CREWNFT from "../contracts/Superheroes.json";

const CREW_TOKEN = '0x1f89600A0a08bC51Eef1BeC8E1CA826145753052';
const CREW_NFT = '0x24AA93b7ABe09B5E55C5B29758282d05799ecea5';
const CREW_HARVEST = '0x005083591533066d4deD4c5729c74142E0C35312';

const MORALIS_API_KEY = "BDxDa18xEo0yxx8noX1LnzRwzglkVFBAjMejxds6UzUw6pqMne3CVbdzROefxyvA";

const mintFee = '10'


export const useWalletConnect = () => {

  const { connect: connectWallet, connectors, error, isLoading, pendingConnector } = useConnect();
  const { disconnect: disconnectWallet } = useDisconnect();
  const { address: account, connector, isConnected } = useAccount();

  useEffect(() => {
    (async () => {
      try {
        await Moralis.start({
          apiKey: MORALIS_API_KEY
        });
        console.log('moralis started');
      } catch (e) {
        console.error('moralis error:', e);
      }
    })();
  }, []);

  const connect = useCallback(async () => {
    if (!isConnected) {
      try {
        //console.log("wallet connecting")
        if (connectors.length)  {
          connectWallet({ connector: connectors[0] });
        } else {
          connectWallet({ connector });
        }
        await Moralis.start({
          apiKey: MORALIS_API_KEY
        });
      } catch (e) {
        console.error(e)
      }
    }
  }, [connectors, connector, connectWallet])

  const disconnect = useCallback(() => {
    try {
      //console.log("wallet disconnecting")
      if (isConnected) disconnectWallet();
    } catch (e) {
      console.error(e)
    }
  }, [disconnectWallet])

  return {
    isLoading,
    pendingConnector,
    connectError: error,
    isConnected,
    account,
    connect,
    disconnect
  }
}

export const useMint = () => {
  const [isBusy, setIsBusy] = useState(false);
  const { address: account, isConnected } = useAccount();
  const { data: signer, isError } = useSigner();
  const pulse = useBlockPulse();
  const provider = getProvider();

  const [contractData, setContractData] = useState({
    totalSupply: 0,
    maxSupply: 10000,
    stage: 0,
  });

  useEffect(() => {
    (async () => {
      if (account) {
        try {
          console.log("pulse effect... ");

          const contract = new Contract(
            CREW_NFT,
            CREWNFT.abi,
            provider
          );

          //stage 0 - Paused, stage 1 - Minting
          const _stage = Number((await contract.mintStage()).toString());
          console.log("stage: ", _stage);

          //user mint count so far
          //const _mints = Number((await contract.limitIndex(account)).toString())
          //console.log("mints: ", _mints)
          //stage 0 - Paused

          //current total minted supply
          const _total = Number((await contract.totalSupply()).toString());
          console.log("total: ", _total);

          setContractData({
            stage: _stage || 0,
            totalSupply: _total || 0,
            maxSupply: 7777,
            isConnected,
            isPaused: _stage === 0,
            isSupplyLimit: _total >= 7777,
          });
        } catch (e) {
          console.error(e);
        }
      }
    })();
  }, [account, pulse]);

  const mint = useCallback(
    async (amount) => {
      if (!amount || Number(amount) === 0 || !signer) return;
      console.log("amount: ", amount);

      const { totalSupply } = contractData;

      if (totalSupply >= 7777) {
        console.error("Max mintable supply reached");
        return;
      }

      const contract = new Contract(CREW_NFT, CREWNFT.abi, signer);

      setIsBusy(true);
      try {

        const value = utils.parseEther(
          (Number(mintFee) * Number(amount.toString())).toString()
        );
        console.log("value: ", value);

        let _tx = await contract.connect(signer).mint(
          amount.toString(), //amount to mint
          { value } //mint fee
        );

        console.log("tx hash: ", _tx?.hash);
        let _receipt = await _tx.wait();
        console.log("tx receipt: ", _receipt);
        setIsBusy(false);
        return true;
      } catch (error) {
        console.error("Minting Error: ", error);
        setIsBusy(false);
        return false;
      }
    },
    [provider, pulse]
  );

  return {
    account,
    contractData,
    mint,
  };
};

export const useCollection = () => {
  const [loading, setLoading] = useState(false);
  const [harvesting, setHarvesting] = useState(false);
  const { address: account, isConnected } = useAccount();
  const { data: signer, isError } = useSigner();
  const [collection, setCollection] = useState([]);
  const pulse = useBlockPulse();
  const provider = getProvider();

  useEffect(() => {
    (async () => {
      if (account) {
        //setLoading(true);
        try {
          console.log("pulse effect... ");

          const _data = await Moralis.EvmApi.nft.getWalletNFTs({
            "chain": "0x89",
            "format": "decimal",
            "tokenAddresses": [CREW_NFT],
            "mediaItems": true,
            "address": account
          });

          const _collection = _data?.raw?.result || [];

          //setCollection(_collection);

          const contract = new Contract(
            CREW_HARVEST,
            CrewHarvest.abi,
            provider
          );

          for (let i=0; i<_collection.length; i++) {
            const _imageUrl = _collection[i].media?.original_media_url || '';
            _collection[i].image = `https://ipfs.io/ipfs/${_imageUrl.substring(7)}`;
            _collection[i].title = `${_collection[i].name} #${_collection[i].token_id}`
            _collection[i].harvestAmount = utils.formatEther(
              (await contract.harvestAmount(_collection[i].token_id)).toString()
            );
          }
          console.log('collection: ', _collection)
          setCollection(_collection);
          //setLoading(false);

        } catch (e) {
          //setLoading(false);
          console.error(e);
        }
      }
    })();
  }, [account, pulse]);

  const harvest = useCallback(
    async (tokenId) => {
      if (!tokenId || Number(tokenId) === 0 || !signer) return;
      setHarvesting(true);
      const contract = new Contract(CREW_HARVEST, CrewHarvest.abi, signer);
      try {
        console.log("harvesting token: ", tokenId);

        let _tx = await contract.connect(signer).harvest(
          tokenId.toString()
        );
        console.log("tx hash: ", _tx?.hash);
        let _receipt = await _tx.wait();
        console.log("tx receipt: ", _receipt);
        setHarvesting(false);
        return true;
      } catch (error) {
        console.error("Harvest Error: ", error);
        setHarvesting(false);
        return false;
      }
    },
    [provider, pulse]
  );

  return {
    account,
    loading,
    harvesting,
    collection,
    harvest
  };
};

let blocking
const useBlockPulse = () => {

  const [block, setBlock] = useState("");
  const [pulse, setPulse] = useState("");
  const provider = getProvider();

  const updateBlock = useCallback(
    _block => {
      //console.log("updateBlock", _block.toString());
      setBlock(_block.toString())
    },
    [setBlock]
  )

  useEffect(() => {
    if (provider) {
      console.log("setting up block watch...");
      provider.on("block", updateBlock)
      return () => provider.off("block", updateBlock)
    }
  }, [provider])

  useEffect(() => {
    if (blocking) {
      clearTimeout(blocking)
    }
    blocking = setTimeout(() => {
      blocking = false
      console.log("updatePulse", block);
      setPulse(block)
    }, "5000")
  }, [block])

  return pulse
}
