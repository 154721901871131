import React from "react";
import LocomotiveScroll from "locomotive-scroll";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import Mana from "./image/manas.jpg";
import hall from "./hall.jpg";
import Cards from "./Cards";
import { Grid } from "@mui/material";
import "../Qualify_Component/styles/styles.css";

const Set1 = () => {
  return (
    <div id="set1">
      <SkeletonTheme baseColor="#482b94" highlightColor="#583e9e">
        <div id="page">
          <a id="top"></a>

          <main className="page-template-page-mintings back_under">
            <section>
              <div className="bg">
                <div className="gradient-top"></div>
                <div className="gradient-bottom"></div>
              </div>
              <h1
                className="hero_head"
                style={{ textAlign: "center" }}
                id="HallofHeroes"
              >
                <br />
                <br />
                Hall of Heroes
              </h1>{" "}
              <br />
              <div className='container'>
              <img src={hall} style={{ width: "100%", height: "100%" }} />
              </div>
            
              <br />
              <h1 className="hero_head" style={{ textAlign: "center" }}>
                <br />
                <br />
                Set 1
              </h1>
              <br />
              <h1 className=" h1 head_under">
                10,000 UNIQUE NFT’S
                <br />
                1,000 of all 10 superheroes
              </h1>
              <br />
              <br />
              <p className=" h1 head_under_second">
                A set of 10,000 unique cards of the CRYPTO CREW superheroes.{" "}
                <br /> The cards will feature varying degrees of rarity, and
                some will <br /> have unique attributes.
              </p>
              <br />
              <br />
              <p className=" h1 head_under_second head_underz ">
                Hover on it To Reveal Them
              </p>
              <Grid container>
                <Grid md={12} lg={12} xs={12} sm={12}>
                  <br />
                  <Cards />
                </Grid>
              </Grid>
              <br />
              <br />
              <div className="video_css"></div>
              <br />
              <br />
              <br />
              <br />
              <div className="video_css">
                <p className="hsb"></p>
              </div>
              <br />
              <br />
              <div className="row">
                <div className="flex grid">
                  <div className="gradient-bottom"></div>
                </div>
              </div>
            </section>
          </main>
        </div>
      </SkeletonTheme>
    </div>
  );
};

export default Set1;
