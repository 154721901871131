import React from "react";
import TDSldier from "./Roads";
import { Grid } from "@mui/material";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import merch1 from "./image/merch1.jpg";
import merch2 from "./image/merch2.jpg";
import merch3 from "./image/merch3.jpg";
import merch4 from "./image/merch4.jpg";
import merch5 from "./image/merch5.jpg";
import merch6 from "./image/merch6.jpg";
const Roadmap = () => {
  return (
    <div>
      <SkeletonTheme baseColor="#482b94" highlightColor="#583e9e">
        <div id="page">
          <a id="top"></a>
          <main className="page-template-page-minting">
            <section id="hero">
              <div className="bg">
                <div className="gradient-top"></div>
              </div>
              <Grid container></Grid>
              <p className="hsb"></p>
              <div className="row">
                <h1 className="hero_head">
                  <br />
                  Crypto Crew Merch
                </h1>
                <br />
                <br />
                <Grid container spacing={2}>
                  <Grid
                    md={12}
                    lg={12}
                    xs={12}
                    sm={12}
                    style={{ textAlign: "center" }}
                  >
                    {" "}
                    <br />
                    <br />
                    <br />
                    <a href="https://www.redbubble.com/people/The-MATIC-Man/explore?page=1&sortOrder=recent" target="_blank">
                      <button class="button">Red Bubble</button>
                    </a>
                    <br />
                    <br />
                    <br />
                    <a href="https://shop.gnarafdao.xyz/pages/cryptocrew" target="_blank">
                      <button class="button">
                        Gnar Gear
                      </button>
                    </a>
                  </Grid>
                </Grid>

                <Grid container spacing={2}>
                  <Grid
                    md={4}
                    lg={4}
                    xs={12}
                    sm={12}
                    style={{ textAlign: "center" }}
                  >
                    {" "}
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <img src={merch1} style={{ width: "100%" }} />
                  </Grid>

                  <Grid
                    md={4}
                    lg={4}
                    xs={12}
                    sm={12}
                    style={{ textAlign: "center" }}
                  >
                    {" "}
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <img src={merch2} style={{ width: "100%" }} />
                  </Grid>

                  <Grid
                    md={4}
                    lg={4}
                    xs={12}
                    sm={12}
                    style={{ textAlign: "center" }}
                  >
                    {" "}
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <img src={merch3} style={{ width: "100%" }} />
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid
                    md={4}
                    lg={4}
                    xs={12}
                    sm={12}
                    style={{ textAlign: "center" }}
                  >
                    {" "}
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <img src={merch4} style={{ width: "100%" }} />
                  </Grid>

                  <Grid
                    md={4}
                    lg={4}
                    xs={12}
                    sm={12}
                    style={{ textAlign: "center" }}
                  >
                    {" "}
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <img src={merch5} style={{ width: "100%" }} />
                  </Grid>

                  <Grid
                    md={4}
                    lg={4}
                    xs={12}
                    sm={12}
                    style={{ textAlign: "center" }}
                  >
                    {" "}
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <img src={merch6} style={{ width: "100%" }} />
                  </Grid>
                </Grid>
                <div className="flex grid">
                  <div className="image"></div>
                  <div className="gradient-bottom"></div>
                </div>
              </div>
            </section>
          </main>
        </div>
      </SkeletonTheme>
    </div>
  );
};

export default Roadmap;
