import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import Timer from "./Timer.jsx";
const HeroSection = () => {
  return (
    <div>
      {" "}
      <SkeletonTheme baseColor="#482b94" highlightColor="#583e9e">
        <div id="page">
          <a id="top"></a>

          <main className="page-template-page-minting">
            <section id="hero">
              <div className="bg">
                <div className="gradient-top"></div>
                <div className="gradient-bottom"></div>
              </div>
              <div className="row">
                <h1 className="hero_head light">the crypto universe is in danger.</h1>
                <div className="flex grid">
                  <div className="image">
                    <img src="/images/heroes-2.png" layout="fill" priority />
                    <div className="text">
                      <h2>
                       
                      </h2>
                    </div>
                  </div>

                  <div className="gradient-bottom"></div>
                </div>
              </div>
            </section>
          </main>
        </div>
      </SkeletonTheme>
    </div>
  );
};

export default HeroSection;
