import React from "react";
import { Grid } from "@mui/material";
import Mana from "./image/manas.jpg";
import fantom from "./image/fantom.jpg";
import ether from "./image/ether.jpg";
import cro from "./image/cro.jpg";
import bitman from "./image/bitman.jpg";
import Avalanche from "./image/avalanche.jpg";
import Sol from './image/sol.jpg'
import Ada from './image/ada.jpg'

const Cards = () => {
  return (
    <div className="container pad">
      <Grid container spacing={2}>
        <Grid item md={6} lg={3} xs={6} sm={6}>
          <div class="containerho">
            <img src={Mana} alt="" />
            <div class="overlay ">
              <span className="head_underz font_cards">Mana</span>
            </div>
          </div>
        </Grid>
        <Grid item md={6} lg={3} xs={6} sm={6}>
          <div class="containerho">
            <img src={fantom} alt="" />
            <div class="overlay">
              <span className="head_underz font_cards">Fantom</span>
            </div>
          </div>
        </Grid>
        <Grid item md={6} lg={3} xs={6} sm={6}>
          <div class="containerho">
            <img src={ether} alt="" />
            <div class="overlay">
              <span className="head_underz font_cards">Ether</span>
            </div>
          </div>
        </Grid>
        <Grid item md={6} lg={3} xs={6} sm={6}>
          <div class="containerho">
            <img src={cro} alt="" />
            <div class="overlay">
              <span className="head_underz font_cards">Cro</span>
            </div>
          </div>
        </Grid>

        <Grid item md={6} lg={3} xs={6} sm={6}>
          <div class="containerho">
            <img src={bitman} alt="" />
            <div class="overlay ">
              <span className="head_underz font_cards">Bitman</span>
            </div>
          </div>
        </Grid>
        <Grid item md={6} lg={3} xs={6} sm={6}>
        <div class="containerho">
          <img src={Avalanche} alt="" />
          <div class="overlay ">
            <span className="head_underz font_cards">Avalanche</span>
          </div>
        </div>
      </Grid>
      <Grid item md={6} lg={3} xs={6} sm={6}>
      <div class="containerho">
        <img src={Sol} alt="" />
        <div class="overlay ">
          <span className="head_underz font_cards">Sol</span>
        </div>
      </div>
    </Grid>
    <Grid item md={6} lg={3} xs={6} sm={6}>
    <div class="containerho">
      <img src={Ada} alt="" />
      <div class="overlay ">
        <span className="head_underz font_cards">Ada</span>
      </div>
    </div>
  </Grid>
       
      </Grid>
    </div>
  );
};

export default Cards;
