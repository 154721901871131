import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import TDSldier from "./TeamSlider";
import { Grid } from "@mui/material";
import AOS from "aos";
import "aos/dist/aos.css";
AOS.init({
  duration: 2500,
});
const OurTeam = () => {
  return (
    <div  id="ourteam">
      <SkeletonTheme baseColor="#482b94" highlightColor="#583e9e">
        <div id="page">
          <a id="top"></a>
          <main className="page-template-page-minting">
            <section id="hero">
              <div className="bg">
                <div className="gradient-top"></div>
                <div className="gradient-bottom"></div>
              </div>
              <p className="hsb"></p>
              <div className="row">
             
                <h1 className="hero_head">
                  <br />
                  Our Team
                </h1>
                <Grid container>
                  <Grid md={12} lg={12} xs={12} sm={12}>
                    {" "}
                    <TDSldier />
                  </Grid>
                </Grid>

                <div className="flex grid">
                  <div className="image"></div>

                  <div className="gradient-bottom"></div>
                </div>
              </div>
            </section>
          </main>
        </div>
      </SkeletonTheme>
    </div>
  );
};

export default OurTeam;
