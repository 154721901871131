import React from "react";
import { Grid } from "@mui/material";
function MobileGamingHero() {
  return (
    <div className="background_of_gaming_mb">
      <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br />
      <div className="container">
        <Grid container spacing={2}>
          <Grid item md={7} lg={7}>
            <h1 className="head_gaming">POLYGON HEROES </h1>
            <br /> <br />
            <p className="under_gaming">
              The crypto universe is under attack by evil villains from the
              Legion of Gloom. Brave warriors are needed to rise up and stave
              off these fiendish foes.
              <br /> <br />
              Do you have what it takes to be victorious? Are you ready to be
              one of the Polygon Heroes?
              <br /> <br />
              You can play the free version simply by owning a Crypto Crew NFT
              or try the Risk-2-Earn mode using the $CREW token with the
              opportunity to win tokens if victorious.
              <br /> <br />
              What are you waiting for? Enter the battle today.
            </p>{" "}
            <br />
            <hr style={{ color: "#fff", height: "30px" }}></hr>
            <br />{" "}
            <a
              href="https://polygonheroes.cryptocrewnft.com/"
              target="_blank"
              class=""
            >
              <li className="li">
                <div class="download btn-gradgold">
                 Play Now
                </div>
              </li>
            </a>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default MobileGamingHero;
