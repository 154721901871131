import "./App.css";
import Home from "./screens/Home";
import Lore from "./screens/Consult";
import Merch from "./screens/Merch";
import Qualify from "./screens/Qualify";
import FOF from "./screens/FOF";
import ShowNFTs from "./screens/Show";
import Gaming from "./screens/Gaming";
import GameRun from './screens/GameRun'


import Grid from "@mui/material/Grid";

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import { ThemeProvider } from "@mui/material/styles";
import theme from "./Theme";
import Roadmap from "./screens/Roadmap";
import { TransitionProvider } from "./context/TransitionContext";
import TransitionComponent from "./Transition.js";
import { configureChains, createClient, WagmiConfig } from "wagmi";
import { polygon } from "wagmi/chains";
import { publicProvider } from "wagmi/providers/public";

import { CoinbaseWalletConnector } from "wagmi/connectors/coinbaseWallet";
import { InjectedConnector } from "wagmi/connectors/injected";
import { MetaMaskConnector } from "wagmi/connectors/metaMask";
import { WalletConnectConnector } from "wagmi/connectors/walletConnect";

const { chains, provider } = configureChains([polygon], [publicProvider()]);
const client = createClient({
  provider,
  autoConnect: true,
  connectors: [
    new MetaMaskConnector({ chains }),
    /*
      new CoinbaseWalletConnector({
        chains,
        options: {
          appName: "wagmi",
        },
      }),
      new WalletConnectConnector({
        chains,
        options: {
          projectId: "...",
        },
      }),
      new InjectedConnector({
        chains,
        options: {
          name: "Injected",
          shimDisconnect: true,
        },
      }),*/
  ],
});

function App() {
  return (
    <WagmiConfig client={client}>
      {" "}
      <div className="App">
        <ThemeProvider theme={theme}>
          <TransitionProvider>
            {" "}
            <Router>
              <Routes>
                <Route
                  index
                  element={
                    <TransitionComponent>
                      <Home />
                    </TransitionComponent>
                  }
                />
                <Route
                  path="/minting"
                  element={
                    <TransitionComponent>
                      <Qualify />
                    </TransitionComponent>
                  }
                />
                <Route
                  path="/roadmap"
                  element={
                    <TransitionComponent>
                      <Roadmap />
                    </TransitionComponent>
                  }
                />
                <Route
                  path="/lore"
                  element={
                    <TransitionComponent>
                      <Lore />
                    </TransitionComponent>
                  }
                />
                <Route
                  path="/merch"
                  element={
                    <TransitionComponent>
                      <Merch />
                    </TransitionComponent>
                  }
                />
                <Route
                  path="/harvest"
                  element={
                    <TransitionComponent>
                      <ShowNFTs />
                    </TransitionComponent>
                  }
                />{" "}
                <Route
                  path="/Gaming"
                  element={
                    <TransitionComponent>
                      <Gaming />
                    </TransitionComponent>
                  }
                />
                <Route
                  path="/HeroRun"
                  element={
                    <TransitionComponent>
                      <GameRun />
                    </TransitionComponent>
                  }
                />
              </Routes>
            </Router>
          </TransitionProvider>
        </ThemeProvider>
      </div>
    </WagmiConfig>

    // <Grid container >
    //   <Grid item md={6} >
    //     <Slider />
    //   </Grid>
    // </Grid>
  );
}

export default App;
