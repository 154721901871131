import { useEffect, useState, useCallback } from "react";
import { useWalletConnect, useMint } from "../hooks/useCrew";

import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

import { Grid } from "@mui/material";

export default function MintPage() {
  const { isLoading, pendingConnector, connectError, isConnected, connect } =
    useWalletConnect();
  const {
    mint,
    contractData: { stage, totalSupply, maxSupply, isPaused, isSupplyLimit },
  } = useMint();

  const handleButtonClick = useCallback(async () => {
    if (isConnected) await handleMinting();
    else await connect();
  });

  const [mintCount, setMintCount] = useState(1);

  const handleMinting = useCallback(async () => {
    if (stage == 0) return;
    console.log("trying to mint");

    // Signal the wallet to initiate a minting transaction
    const didMint = await mint(mintCount);

    if (didMint) {
      console.log("minted");
    }
  }, [mint, mintCount]);

  const [mintButtonLabel, setMintButtonLabel] = useState("Mint");

  useEffect(() => {
    if (!isConnected) setMintButtonLabel("Connect to Wallet");
    else if (isPaused) setMintButtonLabel("Minting is not currently active");
    else if (isSupplyLimit) setMintButtonLabel("No more NFTs in supply");
    else setMintButtonLabel("Mint " + mintCount);
  }, [isConnected, isPaused, isSupplyLimit, mintCount]);

  return (
    <SkeletonTheme
      baseColor="#482b94"
      highlightColor="#583e9e"
      className="bodys"
    >
      <div id="page">
        <main className="page-template-page-minting">
          <section id="hero">
            <div className="bg">
              <div className="gradient-top"></div>
              <div className="gradient-bottom"></div>
            </div>
            <div className="row">
              <h1>The Crypto Crew</h1>
              <div className="flex">
                <div className="image"></div>
                <div className="text">
                  <p>
                    The CRYPTO CREW superheroes are here to save the day. Set 1
                    is an exclusive set of 10,000 unique NFT’s, 1,000 of each of
                    the 10 CRYPTO CREW superheroes.
                  </p>
                  <p>
                    Your NFT harvests $CREW tokens and also, is your ticket into
                    the amazing CRYPTO CREW universe, giving you access to our
                    entire community (DAO, metaverse, merchandise, music,
                    Engage-2-Earn, Play-2-Earn game, and charitable giving).
                  </p>
                  <hr />
                  <h2>JOIN OUR COMMUNITY</h2>
                  <div id="social">
                    <ul>
                      <li className="telegram">
                        <a
                          target="_blank"
                          href="https://t.me/+U29SX3Tr4PNlMzUx"
                        >
                          Telegram
                        </a>
                      </li>
                      <li className="twitter">
                        <a target="_blank" href="https://t.co/cwHB8R69tF">
                          Twitter
                        </a>
                      </li>
                      <li className="discord">
                        <a target="_blank" href="https://discord.gg/cBKG4ZuK6V">
                          Discord
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="mint">
                  <div className="content">
                    <div className="top">
                      <div className="flex">
                        <p>1 NFT = 10 MATIC</p>
                        <div className="matic">
                          <p>We accept</p>
                          <img
                            src="/images/matic-logo.png"
                            width={100}
                            height={34}
                          />
                        </div>
                      </div>
                      <hr />
                      <div className="flex">
                        <p>
                          Total:{" "}
                          {isConnected ? (
                            maxSupply?.toLocaleString("en-US")
                          ) : (
                            <Skeleton width={100} />
                          )}
                        </p>
                        <p>
                          {isConnected ? (
                            maxSupply - totalSupply
                          ) : (
                            <Skeleton width={48} />
                          )}
                        </p>
                        <p>Remaining</p>
                      </div>
                    </div>

                    {!isConnected ? (
                      <div className="bottom">
                        <p>Please connect your MetaMask</p>
                        <a onClick={handleButtonClick}>{mintButtonLabel}</a>
                      </div>
                    ) : (
                      <div className="bottom">
                        <p>Public Sale</p>
                        <a
                          onClick={() =>
                            setMintCount(mintCount > 1 ? mintCount - 1 : 1)
                          }
                        >
                          -
                        </a>
                        &nbsp; &nbsp;
                        <a onClick={handleButtonClick}>{mintButtonLabel}</a>
                        &nbsp; &nbsp;
                        <a
                          onClick={() =>
                            setMintCount(mintCount < 10 ? mintCount + 1 : 10)
                          }
                        >
                          +
                        </a>
                      </div>
                    )}
                    <div className="bottom">
                      <a
                        target="_new"
                        href="https://www.oneplanetnft.io/collections/items/0x24AA93b7ABe09B5E55C5B29758282d05799ecea5?utm_source=cryptocrew&utm_medium=website&utm_campaign=marketplace+button&sort=price_low_to_high"
                      >
                        Crypto Crew Collection on OnePlanet
                      </a>
                    </div>

                    <Grid container spacing={0}>
                      <Grid item md={6} lg={6} xs={6} sm={6}>
                        {" "}
                        <div className="bottom">
                          <a
                            target="_new"
                            href="https://opensea.io/collection/crypto-crew-superheroes"
                          >
                            OpenSea
                          </a>
                        </div>
                      </Grid>
                      <Grid item md={6} lg={6} xs={6} sm={6}>
                      {" "}
                      <div className="bottom">
                        <a
                          target="_new"
                          href="https://magiceden.io/collections/polygon/0x24aa93b7abe09b5e55c5b29758282d05799ecea5?checkoutTab=buy "
                        >
                        Magic Eden 
                        </a>
                      </div>
                    </Grid>
                    </Grid>

                    <Grid container spacing={0}>
                    <Grid item md={6} lg={6} xs={6} sm={6}>
                      {" "}
                      <div className="bottom">
                        <a
                          target="_new"
                          href="https://rarible.com/collection/polygon/0x24aa93b7abe09b5e55c5b29758282d05799ecea5/items "
                        >
                        Rarible
                        </a>
                      </div>
                    </Grid>
                    <Grid item md={6} lg={6} xs={6} sm={6}>
                    {" "}
                    <div className="bottom">
                      <a
                        target="_new"
                        href="https://www.pi-protocol.io/vault/137/0xe59932223e027d44eb1fe69eae8cce2ced5c3b02  "
                      >
                      Pi Protocol 
                      </a>
                    </div>
                  </Grid>
                  </Grid>
                  </div>
                </div>
                <div className="gradient-bottom"></div>
              </div>
            </div>
          </section>
        </main>
      </div>
    </SkeletonTheme>
  );
}
