import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";
import { Card } from "react-bootstrap";
ChartJS.register(ArcElement, Tooltip, Legend);

export const data = {
  labels: [
    "Reviews Excellent ",
    "Reviews Good",
    "Reviews Great",
    "Reviews Bad",
    
  ],
  datasets: [
    {
      label: "",
      data: [215, 37, 118, 5],
      backgroundColor: [
        "rgba(75, 192, 192, 0.2) ",
        "rgba(54, 162, 235, 0.2)",
        "rgba(255, 206, 86, 0.2)",
        " rgba(255, 99, 132, 0.2)",
        "rgba(75, 192, 192, 0.2) ",
        "rgba(54, 162, 235, 0.2)",
        "rgba(255, 206, 86, 0.2)",
        " rgba(255, 99, 132, 0.2)",
      ],
      borderColor: [
        "  rgba(75, 192, 192, 1)",
        "rgba(54, 162, 235, 1)",
        "rgba(255, 206, 86, 1)",
        "rgba(255, 99, 132, 1) ",
        "  rgba(75, 192, 192, 1)",
        "rgba(54, 162, 235, 1)",
        "rgba(255, 206, 86, 1)",
        "rgba(255, 99, 132, 1) ",
      ],
      borderWidth: 1,
    },
  ],
};

function App() {
  return (
    <div >
      <Card className='review' >
        <h5>Reviews</h5>
        <Pie data={data} />
        <br />
      </Card>
    </div>
  );
}

export default App;
