import React from "react";
import { FaTwitter } from "react-icons/fa";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Grid } from "@mui/material";

import punks from "./image/punks.jpg";
import LocomotiveScroll from "locomotive-scroll";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import legion from "./image/legion.png";
import Cards from "./Cards";
import "../Qualify_Component/styles/styles.css";
import Set2 from "./image/set2.png";

const Set1 = () => {
  return (
    <div id="set2">
      <SkeletonTheme baseColor="#482b94" highlightColor="#583e9e">
        <div id="page">
          <a id="top"></a>

          <main className="page-template-page-mintings back_under">
            <section>
              <div className="bg">
                <div className="gradient-top"></div>
                <div className="gradient-bottom"></div>
              </div>
              <h1 className="hero_head" style={{ textAlign: "center" }}>
                <br />
                <br />
                Set 2
              </h1>
              <br />
              <Card className="card_bg box_Par">
                <CardMedia
                  sx={{ height: 130, width: 130 }}
                  image={legion}
                  title="legion of gloom"
                  className="picpa img_partner"
                />
                <br />
              </Card>
              <h1 className=" h1 head_under">
                10,000 LEGION OF GLOOM
                <br />
                1,000 of all 10 villains
              </h1>

              <br />
              <br />
              <p className=" h1 head_under_second">
                A set of 10,000 unique cards of the CRYPTO CREW superheroes.{" "}
                <br /> The cards will feature varying degrees of rarity, and
                some will <br /> have unique attributes.
              </p>
              <br />
              <br />

              <p className="container hsb">
                <img src={Set2} style={{ width: "100%" }} />
              </p>

              <br />
              <div className="row">
                <div className="flex grid">
                  <div className="gradient-bottom"></div>
                </div>
              </div>
            </section>
          </main>
        </div>
      </SkeletonTheme>
    </div>
  );
};

export default Set1;
